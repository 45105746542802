import BookDownload from './BookDownload';
import React from 'react';
import ReadMore from './ReadMore';
import Tags from './Tags';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT, SIZES } from '../common/theme';
import { Orientation, RequestID } from '../common/enums';
import { getText, getPath } from '../common/utils/helpers';
import { useSelector } from 'react-redux';
import Share from './Share';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      blogBlockContainerStyle: {
        position: 'relative',
        width: '100%',
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
      },
      imgStyle: {
        position: 'relative',
        // minWidth: '370px',
        // width: '370px',
        // height: '240px',
        maxWidth: '370px',
        maxHeight: '240px',
        outline: `1px solid ${COLORS.circleGray}`,
        backgroundColor: COLORS.circleGray,
      },
      imgOnlyStyle: {
        // width: '370px',
        // height: '240px',
        maxWidth: '370px',
        maxHeight: '240px',
        minWidth: '370px',
        minHeight: '240px',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      verticalImgStyle: {
        objectFit: 'contain',
      },
      imgWhiteBlockStyle: {
        position: 'absolute',
        width: '30px',
        height: '180px',
        right: 0,
        bottom: 0,
        borderLeft: `1px solid ${COLORS.circleGray}`,
        borderTop: `1px solid ${COLORS.circleGray}`,
        backgroundColor: COLORS.white,
      },
      textBlockStyle: {
        position: 'relative',
        width: '100%',
        maxWidth: '830px',
        minHeight: '180px',
        margin: '61px 0 0 -29px',
        boxSizing: 'border-box',
        padding: '26px 0 0 30px',
        display: 'flex',
        flexDirection: 'column',
        background: COLORS.white,
        textAlign: 'justify',
      },
      searchBlockStyle: {
        maxWidth: `${SIZES.maxSearchBlockWidth}px`,
      },
      titleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '20px',
        textAlign: 'left',
      },
      searchTitleStyle: {
        textAlign: 'left',
      },
      textStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '16px',
        marginTop: '10px',
        '& a:link': {
          color: COLORS.primary,
          textDecoration: 'none',
        },
        '& a:visited': {
          color: COLORS.primary,
        },
        '& a:hover': {
          color: COLORS.textBlack,
        },
      },
      downloadStyle: {
        position: 'absolute',
        right: 0,
        top: '-20px',
      },
      imageClickStyle: {
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      clickStyle: {
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: COLORS.primary,
            transition: '0.5s ease',
          },
        },
      },
      bottomWrapperStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      shareParentStyle: {
        margin: '16px 0 0 auto',
      },
      [`@media (max-width: 1230px)`]: {
        searchBlockStyle: {
          maxWidth: '100%',
        },
      },
      [`@media (max-width: 900px)`]: {
        blogBlockContainerStyle: {
          flexDirection: 'column',
        },
        textBlockStyle: {
          padding: 0,
          margin: '10px 0 0 0',
        },
        imgWhiteBlockStyle: {
          display: 'none',
        },
        downloadStyle: {
          position: 'absolute',
          right: 0,
          top: '-50px',
        },
        imgStyle: {
          overflow: 'hidden',
        },
      },
      [`@media (max-width: 400px)`]: {
        bottomWrapperStyle: {
          flexDirection: 'column',
        },
      },
    })
);

interface IProps {
  data: any;
  onBlockOpen: (data?: any, link?: string) => void;
  isClickDisabled?: boolean;
  type?: RequestID;
}

const BlogBlock = ({
  data,
  onBlockOpen,
  isClickDisabled,
  type,
}: IProps): JSX.Element => {
  const { content, images, pdf } = data;

  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const title: string = getText(content, 'title', lng);
  const intro: string = getText(content, 'intro', lng);
  const text: string = getText(content, 'text', lng);
  const link: string = getText(content, 'link', lng);
  const rawText = intro || text;
  const displayText = rawText?.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const { cover, tags_lv, tags_en } = content;

  const coverImageData =
    cover && images?.find((item) => cover.includes(item.filename));
  const currentImageData = coverImageData || images?.[0];
  const currentImg: string = `${urlPrefix}${currentImageData?.url}`;
  const isPortrait =
    currentImageData?.dimensions?.orientation === Orientation.PORTRAIT;

  const pathname = window.location.pathname;
  const isInfo = pathname.includes('info/');
  const isPDFDownload = Boolean(pdf?.length) && !isInfo;
  const pdfFile = isPDFDownload && pdf?.[0];

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (isPDFDownload && pdfFile) {
      const link: string = getText(pdfFile.meta, 'link', lng);

      if (link) {
        return link && window.open(link, '_blank');
      } else {
        const [currentPDF] = pdf;
        const currentFileUrl: string = `${urlPrefix}${currentPDF?.url}`;

        return window.open(currentFileUrl, '_blank');
      }
    }

    return onBlockOpen(data, link);
  };

  const clickProps = {
    ...(!isClickDisabled ? { onClick } : {}),
  };

  const isSearch = type === RequestID.SEARCH;

  return (
    <div className={classes.blogBlockContainerStyle}>
      {currentImageData ? (
        <div
          className={clsx(
            classes.imgStyle,
            !isClickDisabled && classes.imageClickStyle
          )}
          {...clickProps}
        >
          <img
            src={currentImg}
            alt=""
            className={clsx(
              classes.imgOnlyStyle,
              isPortrait && classes.verticalImgStyle
            )}
          />
          <div className={classes.imgWhiteBlockStyle} />
        </div>
      ) : (
        <div className={classes.imgStyle} />
      )}
      <div
        className={clsx(
          classes.textBlockStyle,
          isSearch && classes.searchBlockStyle
        )}
      >
        {isPDFDownload && (
          <div className={classes.downloadStyle}>
            <BookDownload file={pdfFile} />
          </div>
        )}
        <h4
          className={clsx(
            classes.titleStyle,
            !isClickDisabled && classes.clickStyle,
            isSearch && classes.searchTitleStyle
          )}
          {...clickProps}
        >
          {title}
        </h4>
        {(tags_lv || tags_en) && <Tags tags_lv={tags_lv} tags_en={tags_en} />}
        <div
          className={classes.textStyle}
          dangerouslySetInnerHTML={{
            __html: displayText,
          }}
        />
        <div className={classes.bottomWrapperStyle}>
          {!isClickDisabled && !isPDFDownload && <ReadMore onClick={onClick} />}
          <div className={classes.shareParentStyle}>
            <Share
              title={title}
              text={displayText}
              image={currentImageData}
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBlock;
