const scrollParentToChildPos = (
  child: HTMLElement | null,
  childDepth: number = 1,
  behavior: ScrollBehavior = 'smooth'
) => {
  if (!child || !child.parentElement) {
    return;
  }
  let parent: HTMLElement | null = child;
  for (let i = 0; i < childDepth; i++) {
    parent = parent.parentElement;
    if (!parent) {
      return;
    }
  }
  const finalPosition: number =
    child.offsetLeft - parent.clientWidth * 0.5 + child.clientWidth * 0.5;

  if (parent.scrollTo) {
    parent.scrollTo({
      left: finalPosition,
      behavior,
    });
  } else {
    parent.scrollLeft = finalPosition;
  }
};

export default scrollParentToChildPos;
