import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { useSelector } from 'react-redux';
import { getText } from '../common/utils/helpers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      contactsContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '10px',
      },
      tableContainerStyle: {
        marginTop: '20px',
      },
      tableRowStyle: {
        '& > :first-child': {
          minWidth: '100px',
          fontWeight: FONT_WEIGHT.regular,
        },
        '& > :last-child': {
          paddingRight: 0,
          fontWeight: FONT_WEIGHT.regular,
        },
        '& > td': {
          paddingLeft: 0,
          verticalAlign: 'top',
          fontWeight: FONT_WEIGHT.bold,
        },
      },
      tableCellStyle: {
        fontFamily: FONTS.alegreya,
        fontSize: '16px',
        '& a:link': {
          color: COLORS.primary,
          textDecoration: 'none',
        },
        '& a:visited': {
          color: COLORS.primary,
        },
        '& a:hover': {
          color: COLORS.textBlack,
        },
      },
    })
);

const SimpleTable = ({ data }): JSX.Element => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const getTableCell = (row, rowKey, index) => {
    const itemName = row[rowKey];
    const isEmail = itemName.includes('@') && itemName.includes('.');
    const sanitizedName = isEmail
      ? `<a href="mailto:${itemName}">${itemName}</a>`
      : itemName;

    return (
      <TableCell
        key={index}
        className={classes.tableCellStyle}
        dangerouslySetInnerHTML={{
          __html: sanitizedName,
        }}
      />
    );
  };

  return (
    <div className={classes.contactsContainerStyle}>
      <TableContainer className={classes.tableContainerStyle}>
        <Table>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className={classes.tableRowStyle}>
                {Object.keys(row).map((rowKey, index) =>
                  getTableCell(row, rowKey, index)
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SimpleTable;
