import CookieConsent from 'react-cookie-consent';
import React, { useEffect } from 'react';
import { FONTS, FONT_WEIGHT } from '../common/theme';
import { RequestID } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const COOKIES_PAGE_URL = './info/par-emuzejs-lnvm-lv/datu-drosiba-un-privatums';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      cookieContent: {
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: 'lightGray',
        fontSize: '14px',
        lineHeight: '16px',
        '& a:link': {
          color: 'lightGray',
        },
        '& a:visited': {
          color: 'gray',
        },
        '& a:hover': {
          color: 'white',
        },
      },
      cookieButton: {
        textTransform: 'uppercase',
      },
    })
);

const Cookies = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);

  const { [RequestID.COOKIES]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.COOKIES));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return null;
  }

  const { content } = currentData;

  const linkText: string = getText(content, 'title', lng);
  const text: string = getText(content, 'text', lng);
  const button: string = getText(content, 'button', lng);

  return (
    <CookieConsent
      location="bottom"
      buttonText={button}
      cookieName="emuzejs.lnvm.lv"
      contentClasses={classes.cookieContent}
      buttonClasses={classes.cookieButton}
    >
      {text}{' '}
      <a href={COOKIES_PAGE_URL} target="_self">
        {linkText}
      </a>
    </CookieConsent>
  );
};

export default Cookies;
