import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  title: string;
  data: any[];
  id: string;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      searchSelectContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
      },
      titleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '20px',
      },
      searchSelectWrapperStyle: {
        marginTop: '10px',
        '& > div': {
          width: '100%',
        },
      },
      itemStyle: {
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: COLORS.primary,
            transition: '0.5s ease',
          },
        },
      },
      [`@media (max-width: 1230px)`]: {
        searchSelectContainerStyle: {
          minWidth: '200px',
        },
      },
    })
);

const SearchSelect = ({ title, id, data }: IProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const lng = useSelector((state) => state.app.lng);
  const { [id]: types } = queryString.parse(location?.search);
  const [currentTypes, setTypes] = useState({});

  useEffect(() => {
    if (types) {
      const newTypes = {};
      const allTypes = types.split(',');

      for (const type of allTypes) {
        newTypes[type] = true;
      }
      setTypes(newTypes);
    }
  }, []);

  const onChange = (event) => {
    const value = event.target.name;

    const newTypes = [];
    Object.keys(currentTypes).map((item) => {
      if (item !== value) {
        newTypes.push(item);
      }
    });

    if (!currentTypes[value]) {
      newTypes.push(value);
    }

    const allTypesAsString = newTypes.join(',');

    const currentQuery = queryString.parse(location?.search);
    const newQuery = { ...currentQuery };
    newQuery[id] = allTypesAsString;

    Object.keys(newQuery).forEach(
      (k) => newQuery[k] == null || (newQuery[k] === '' && delete newQuery[k])
    );

    const search = queryString.stringify(newQuery);

    history.push({
      pathname: '/search',
      search,
    });
  };

  const getCheckbox = (item, index) => {
    const value = item?.value;
    const text: string = item?.[`text_${lng}`];
    const checked = !!currentTypes?.[value];

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={value}
            color="default"
          />
        }
        className={classes.itemStyle}
        label={text}
        key={index}
      />
    );
  };

  return (
    <div className={classes.searchSelectContainerStyle}>
      <div className={classes.titleStyle}>{title}</div>
      <div className={classes.searchSelectWrapperStyle}>
        <FormGroup>{data.map(getCheckbox)}</FormGroup>
      </div>
    </div>
  );
};

export default SearchSelect;
