import * as ROUTES from './common/routes';
import Home from './components/Home';
import Layout from './components/Layout';
import React from 'react';
import configureStore from './redux/configureStore';
import About from './components/About';
import StoryWrapper from './components/StoryWrapper';
import AboutSub from './components/AboutSub';
import BlocksWrapper from './components/BlocksWrapper';
import TableWrapper from './components/TableWrapper';
import BlogList from './components/BlogList';
import SearchList from './components/SearchList';
import TagsPage from './components/TagsPage';
import NotFound from './components/NotFound';
import {
  CssBaseline,
  MuiThemeProvider,
  Theme,
  StylesProvider,
} from '@material-ui/core';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Store } from 'redux';
import { getTheme } from './common/theme';
import { RequestID } from './common/enums';

const store: Store = configureStore();
const theme: Theme = getTheme();

const App = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider injectFirst>
          <Router>
            <Layout>
              <Switch>
                <Route exact path={ROUTES.ROOT} component={Home} />
                <Redirect
                  exact
                  from="/resursi/pievieno-savu-stastu-gadsimta-albumam"
                  to="/albums"
                />
                <Route
                  exact
                  path={'/info/par-emuzejs-lnvm-lv'}
                  component={About}
                />
                <Route
                  exact
                  path={'/info/par-emuzejs-lnvm-lv/:pageId'}
                  component={AboutSub}
                />
                <Route exact path={'/info/aktuali'} component={BlogList} />
                <Route
                  exact
                  path={'/info/aktuali/:pageId'}
                  component={StoryWrapper}
                />
                <Route
                  exact
                  path={'/resursi/interaktivas-lietotnes'}
                  component={BlogList}
                />

                <Route
                  exact
                  path={'/resursi/galerijas-un-virtualas-izstades'}
                  component={BlocksWrapper}
                />
                <Route
                  exact
                  path={'/resursi/galerijas-un-virtualas-izstades/:pageId'}
                  component={BlogList}
                />
                <Route
                  exact
                  path={
                    '/resursi/galerijas-un-virtualas-izstades/galerijas/:pageId'
                  }
                  component={StoryWrapper}
                />
                <Route
                  exact
                  path={
                    '/resursi/galerijas-un-virtualas-izstades/galerijas/:pageId/:pageSubId'
                  }
                  component={(routerProps) => <StoryWrapper {...routerProps} />}
                />

                <Route
                  exact
                  path={'/resursi/video-un-audio'}
                  component={BlocksWrapper}
                />
                <Route
                  exact
                  path={'/resursi/video-un-audio/:pageId'}
                  component={BlogList}
                />
                <Route
                  exact
                  path={'/resursi/video-un-audio/:pageId/:pageSubId'}
                  component={(routerProps) => <BlogList {...routerProps} />}
                />

                <Route
                  exact
                  path={'/resursi/petijumi-un-izdevumi'}
                  component={BlocksWrapper}
                />
                <Route
                  exact
                  path={'/resursi/petijumi-un-izdevumi/:pageId'}
                  component={(routerProps) => {
                    const {
                      match: {
                        params: { pageId },
                      },
                    } = routerProps;

                    if (pageId === RequestID.MESSENGER) {
                      return <BlocksWrapper {...routerProps} />;
                    }

                    return <BlogList {...routerProps} />;
                  }}
                />
                <Route
                  exact
                  path={'/resursi/petijumi-un-izdevumi/:pageId/:pageSubId'}
                  component={(routerProps) => <BlogList {...routerProps} />}
                />

                <Route
                  exact
                  path={'/resursi/macibu-materiali'}
                  component={BlocksWrapper}
                />
                <Route
                  exact
                  path={'/resursi/macibu-materiali/gimenem/:pageId'}
                  component={(routerProps) => <BlogList {...routerProps} />}
                />
                <Route
                  exact
                  path={'/resursi/macibu-materiali/:pageId'}
                  component={BlogList}
                />
                <Route
                  exact
                  path={'/resursi/macibu-materiali/skolam/:pageId'}
                  component={TableWrapper}
                />
                <Route
                  exact
                  path={'/search'}
                  component={(routerProps) => <SearchList {...routerProps} />}
                />
                <Route
                  exact
                  path={'/search/:pageId'}
                  component={(routerProps) => <TagsPage {...routerProps} />}
                />

                <Route component={NotFound} path="*" />
              </Switch>
            </Layout>
          </Router>
        </StylesProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
