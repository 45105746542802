import * as C from '../common/constants';
import * as yaml from 'js-yaml';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHeader from './PageHeader';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { COLORS, FONTS } from '../common/theme';
import { fetchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconCheck } from '../assets/svg/check_circle.svg';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      tableWrapperContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '60px',
        flex: '1 1 auto',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      tableContainerStyle: {
        marginTop: '20px',
      },
      tableRowStyle: {
        '& > :first-child': {
          width: '130px',
        },
        '& > :last-child': {
          width: '130px',
          textAlign: 'right',
          paddingRight: 0,
        },
        '& > th': {
          paddingLeft: 0,
          verticalAlign: 'top',
          fontWeight: 'bold',
          lineHeight: '18px',
        },
        '& > td': {
          paddingLeft: 0,
          verticalAlign: 'top',
        },
      },
      tableCellStyle: {
        fontFamily: FONTS.alegreya,
        fontSize: '16px',
        '& a:link': {
          color: COLORS.primary,
          textDecoration: 'none',
        },
        '& a:visited': {
          color: COLORS.primary,
        },
        '& a:hover': {
          color: COLORS.textBlack,
        },
      },
      tableTextStyle: {
        width: '40%',
      },
      iconStyle: {
        width: '20px',
        height: '20px',
        fill: COLORS.primary,
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
    })
);

const TableWrapper = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);

  const id = window.location.pathname;
  const { [id]: currentData } = useSelector((state) => state.api.data);

  useEffect(() => {
    dispatch(fetchData(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  const { content } = currentData;
  const title: string = getText(content, 'title', lng);
  const tableRaw: string = getText(content, 'table', lng);

  const table = yaml.load(tableRaw);
  const header = table.shift();

  return (
    <div className={classes.tableWrapperContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader title={title} />
        <TableContainer className={classes.tableContainerStyle}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRowStyle} key={header.title}>
                <TableCell className={classes.tableCellStyle}>
                  {header.title}
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.tableCellStyle,
                    classes.tableTextStyle
                  )}
                >
                  {header.text}
                </TableCell>
                <TableCell className={classes.tableCellStyle}>
                  {header.comment}
                </TableCell>
                <TableCell className={classes.tableCellStyle}>
                  {header.available}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row, index) => {
                const comment = row.comment.split(';').join(';</li><br /><li>');
                const wrappedComment = `<ul><li>${comment}</li /></ul>`;

                return (
                  <TableRow key={index} className={classes.tableRowStyle}>
                    <TableCell className={classes.tableCellStyle}>
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row.text}
                    </TableCell>
                    <TableCell
                      className={classes.tableCellStyle}
                      dangerouslySetInnerHTML={{
                        __html: wrappedComment,
                      }}
                    />
                    <TableCell className={classes.tableCellStyle}>
                      {row.available === 'V' ? (
                        <IconCheck className={classes.iconStyle} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TableWrapper;
