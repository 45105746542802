import * as C from '../common/constants';
import Footer from './Footer';
import Header from './Header';
import React, { createContext, createRef, useEffect, useState } from 'react';
import Cookies from './Cookies';
import { COLORS } from '../common/theme';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      layoutContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: COLORS.white,
      },
      scrollStyle: {
        marginTop: `${C.HEADER_HEIGHT}px`,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      [`@media (max-width: 600px)`]: {
        scrollStyle: {
          marginTop: '200px',
        },
      },
    })
);

export const ScrollContext = createContext(null);

const Layout = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const [currentRef, setCurrentRef] = useState(null);

  const ref: any = createRef();

  useEffect(() => {
    setCurrentRef(ref.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.layoutContainerStyle}>
      <Header />
      <div className={classes.scrollStyle} ref={ref}>
        <ScrollContext.Provider value={currentRef}>
          {children}
        </ScrollContext.Provider>
        <Footer />
      </div>
      <Cookies />
    </div>
  );
};

export default Layout;
