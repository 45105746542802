import * as C from '../common/constants';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as CloseIcon } from '../assets/svg/cancel_circle.svg';
import { getText } from '../common/utils/helpers';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface Props {
  data: any;
  onClose: () => void;
  startImage?: number;
  content: any;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      dialogContainerStyle: {
        '& > div > div': {
          maxWidth: `${C.MAX_WIDTH}px`,
          width: '100%',
          background: COLORS.white,
        },
      },
      closeButtonStyle: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        fill: COLORS.textGray,
        width: '40px',
        height: '40px',
        pointerEvents: 'auto',
        zIndex: 100,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      dialogContentStyle: {
        margin: '16px',
        color: COLORS.textBlack,
      },
      titleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '20px',
        marginBottom: '8px',
      },
      textStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '18px',
        textAlign: 'justify',
        marginTop: '10px',
      },
      introStyle: {
        marginTop: '12px',
        fontWeight: FONT_WEIGHT.bold,
      },
      galleryStyle: {
        '& > .image-gallery-image': {
          maxHeight: '500px',
        },
      },
      '@global': {
        // '.image-gallery-content': {
        //   display: 'flex',
        //   flexDirection: 'column-reverse',
        // },
        '.image-gallery-thumbnail': {
          width: '70px !important',
        },
        '.image-gallery-slide-wrapper': {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.image-gallery-image': {
          maxHeight: '500px !important',
        },
        '.fullscreen .image-gallery-image': {
          maxHeight: 'calc(100vh - 80px) !important',
        },
        '.image-gallery-description': {
          display: 'none',
        },
        '.fullscreen .image-gallery-description': {
          display: 'block',
          position: 'relative',
          bottom: '0 !important',
          fontFamily: FONTS.alegreya,
          fontWeight: FONT_WEIGHT.medium,
          fontSize: '18px',
          width: '100%',
        },
        '.image-gallery-slide > div': {
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          // width: '100%',
          // height: '100%',
          // flexDirection: 'column',
          flex: '1 1 auto',
        },
        // '.fullscreen .image-gallery-content': {
        //   width: '100%',
        //   height: '100%',
        // },
        [`@media (max-width: 900px)`]: {
          '@global': {
            '.MuiDialog-paper': {
              margin: '0 !important',
            },
          },
        },
      },
    })
);

const GalleryDialog = ({ data, onClose, startImage, content }: Props) => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const [currentSlide, setSlideNumber] = useState(startImage);

  const title: string = getText(content, 'title', lng);

  const hasMultiple: boolean = data.length > 1;
  const correctStartImage = startImage - 1;

  const onSlide = (e) => {
    setSlideNumber(e + 1);
  };

  const currentDescription = data?.[currentSlide - 1]?.description;
  const isMobile = window.innerWidth < 900;

  return (
    <div>
      <Dialog
        fullScreen={isMobile}
        open={true}
        onClose={onClose}
        className={classes.dialogContainerStyle}
      >
        <CloseIcon className={classes.closeButtonStyle} onClick={onClose} />
        <MuiDialogContent className={classes.dialogContentStyle}>
          <div className={classes.titleStyle}>{title}</div>
          <ImageGallery
            items={data}
            startIndex={correctStartImage}
            showThumbnails={hasMultiple}
            showFullscreenButton={true}
            showPlayButton={hasMultiple}
            useTranslate3D={true}
            onClick={onClose}
            onSlide={onSlide}
            thumbnailPosition={'top'}
            additionalClass={classes.galleryStyle}
          />
          <div className={classes.textStyle}>{currentDescription}</div>
        </MuiDialogContent>
      </Dialog>
    </div>
  );
};

export default GalleryDialog;
