import * as C from '../common/constants';
import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng } from '../common/enums';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  onClick: (event: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      readMoreContainerStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '16px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            '& > div': {
              color: COLORS.primary,
              transition: '0.5s ease',
            },
          },
        },
      },
      lineStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '36px',
        height: '1px',
        borderTop: `1px solid ${COLORS.primary}`,
        marginRight: '10px',
      },
      textStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.bold,
        color: COLORS.previewGray,
        fontSize: '16px',
        lineHeight: '16px',
      },
    })
);

const ReadMore = ({ onClick }: IProps) => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const MORE_TEXT: string = lng === Lng.LV ? 'Skatīt vairāk' : 'Read more';

  return (
    <div className={classes.readMoreContainerStyle} onClick={onClick}>
      <div className={classes.lineStyle} />
      <div className={classes.textStyle}>{MORE_TEXT}</div>
    </div>
  );
};

export default ReadMore;
