import * as C from '../common/constants';
import BlogList from './BlogList';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHeader from './PageHeader';
import React, { useEffect, useContext } from 'react';
import Story from './Story';
import { fetchData } from '../common/api/actions';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ScrollContext } from './Layout';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      storyWrapperContainerStyle: {
        position: 'relative',
        width: '100%',
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '60px',
        flex: '1 1 auto',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
    })
);

const StoryWrapper = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollNode = useContext(ScrollContext);

  const id = window.location.pathname;
  const { [id]: currentData } = useSelector((state) => state.api.data);

  useEffect(() => {
    if (history.action !== 'POP') {
      scrollNode?.scrollTo(0, 0);
    }

    dispatch(fetchData(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  if (currentData.pages?.length) {
    return <BlogList id={id} />;
  }

  return (
    <div className={classes.storyWrapperContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader />
        <Story data={currentData} />
      </div>
    </div>
  );
};

export default StoryWrapper;
