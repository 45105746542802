import * as yaml from 'js-yaml';
import GalleryDialog from './GalleryDialog';
import ImageSlider from './ImageSlider';
import React, { useState, useRef } from 'react';
import Share from './Share';
import SimpleTable from './SimpleTable';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconLeft } from '../assets/svg/left-arrow.svg';
import { ReactComponent as IconRight } from '../assets/svg/right-arrow.svg';
import { RequestID, Orientation, Direction } from '../common/enums';
import { getText, getPath } from '../common/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  data: any;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      storyContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
      },
      textContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      titleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '30px',
      },
      textStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '20px',
        marginTop: '16px',
        textAlign: 'justify',
        '& li': {
          textAlign: 'left',
          listStylePosition: 'inside',
          textIndent: '-28px',
          paddingLeft: '28px',
        },
        '& a:link': {
          color: COLORS.primary,
          textDecoration: 'none',
        },
        '& a:visited': {
          color: COLORS.primary,
        },
        '& a:hover': {
          color: COLORS.textBlack,
        },
      },
      introStyle: {
        fontWeight: FONT_WEIGHT.bold,
        marginTop: '16px',
      },
      imageContainerStyle: {
        maxWidth: '570px',
        width: '100%',
        margin: '0 0 10px 30px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      },
      imgStyle: {
        // width: '570px',
        // height: '370px',
        maxWidth: '570px',
        maxHeight: '370px',
        objectFit: 'cover',
        border: `1px solid ${COLORS.circleGray}`,
        cursor: 'pointer',
      },
      verticalImgStyle: {
        objectFit: 'contain',
      },
      noOpenstyle: {
        cursor: 'default',
      },
      thumbContainerStyle: {
        maxWidth: '570px',
        position: 'relative',
        // marginTop: '10px',
        width: '100%',
        height: '140px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      iconWrapperStyle: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        // marginTop: '30px',
        border: `1px solid ${COLORS.backgroundGray}`,
        background: COLORS.backgroundGray,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconStyle: {
        width: '20px',
      },
      leftStyle: {
        left: 0,
      },
      rightStyle: {
        right: 0,
      },
      storyShareStyle: {
        position: 'relative',
        margin: '10px 0 20px 0',
      },
      [`@media (max-width: 1110px)`]: {
        storyContainerStyle: {
          flexDirection: 'column-reverse',
        },
        imageContainerStyle: {
          margin: 0,
        },
      },
      [`@media (max-width: 600px)`]: {
        iconWrapperStyle: {
          display: 'none',
        },
      },
    })
);

const Story = ({ data }: IProps): JSX.Element => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);
  const childRef = useRef<any>();

  const [galleryPhotoNr, setGalleryPhoto] = useState(1);
  const [isGalleryVisible, setGalleryVisible] = useState(false);

  const { content, images } = data;

  const sanitizeText = (passedText: string) => {
    if (passedText?.includes('●')) {
      const adjustedText = passedText
        .split('●')
        .join('<li>')
        .split(';')
        .join(';</li>');

      // return `<ul>${adjustedText}</ul>`;
      return adjustedText;
    }

    return passedText || '';
  };

  const title: string = getText(content, 'title', lng);
  const intro: string = getText(content, 'intro', lng);
  const rawText: string = getText(content, 'text', lng);
  const sanitizedText = sanitizeText(rawText);
  const text = sanitizedText?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  const comment: string = getText(content, 'comment', lng);

  const urlPrefix = getPath();
  const currentImageData = images[galleryPhotoNr - 1];
  const currentImg: string = `${urlPrefix}${currentImageData?.url}`;

  const isPortrait =
    currentImageData?.dimensions?.orientation === Orientation.PORTRAIT;

  const getTableData = () => {
    const tableRaw: string = getText(content, 'table', lng);
    const tableData = tableRaw && yaml.load(tableRaw);

    return tableData;
  };

  const isSecurity: boolean = content.id === RequestID.SECURITY;
  const hasTable: boolean = content.id === RequestID.CONTACTS || isSecurity;
  const tableData = hasTable && getTableData();
  const isSliderVisible: boolean = images?.length > 1;
  const isCoverOnly: boolean = images?.length === 1 && images[0].cover;

  const openGallery = (photoNumber = 1) => {
    setGalleryVisible(true);
    setGalleryPhoto(photoNumber);
  };

  const closeGallery = () => {
    // setGalleryPhoto(null);
    setGalleryVisible(false);
  };

  const items: any[] = [];

  const getImageItem = (image) => {
    const { content: imageContent } = image;
    const description: string =
      (imageContent && getText(imageContent, 'text', lng)) || '';
    const original: string = `${urlPrefix}${image.url}`;
    const thumbnail: string = `${urlPrefix}${image.thumb}`;

    const item = {
      original,
      thumbnail,
      description,
    };

    return item;
  };

  if (isSliderVisible) {
    for (const image of images) {
      if (image?.cover) {
        continue;
      }

      const item = getImageItem(image);

      items.push(item);
    }
  }

  // if (!isSliderVisible && isCoverOnly) {
  //   const item = getImageItem(images[0]);
  //   items.push(item);
  // }

  const isArrowsVisible: boolean = items?.length > 4;

  const getGallery = () => {
    return (
      <GalleryDialog
        content={content}
        data={items}
        onClose={closeGallery}
        startImage={galleryPhotoNr}
      />
    );
  };

  const GoogleMap = ({ url }) => {
    return (
      <div className="google-map-code">
        <iframe
          src={url}
          width="640"
          height="640"
          frameBorder="0"
          style={{ border: 0 }}
        />
      </div>
    );
  };

  const googleMapUrl: string = getText(content, 'google', lng);

  const onArrowClick = (event?: any) => {
    event?.stopPropagation?.();
    event?.preventDefault?.();

    const direction = event?.target?.getAttribute('data-name');

    if (childRef) {
      childRef.current?.goTo(direction);
    }
  };

  const onImageClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation?.();
    event?.preventDefault?.();

    if (isCoverOnly) {
      return;
    }

    // const screenWidth = window.innerWidth;

    // if (screenWidth > 1170) {
    openGallery(galleryPhotoNr);
    // }
  };

  return (
    <div className={classes.storyContainerStyle}>
      <div className={classes.textContainerStyle}>
        <h3 className={classes.titleStyle}>{title}</h3>
        <div
          className={clsx(classes.textStyle, classes.introStyle)}
          dangerouslySetInnerHTML={{
            __html: intro,
          }}
        />
        <div
          className={classes.textStyle}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {googleMapUrl && <GoogleMap url={googleMapUrl} />}
        {hasTable && !!tableData && <SimpleTable data={tableData} />}
        {isSecurity && (
          <div
            className={classes.textStyle}
            dangerouslySetInnerHTML={{
              __html: comment,
            }}
          />
        )}
      </div>
      <div className={classes.imageContainerStyle}>
        <img
          onClick={onImageClick}
          src={currentImg}
          alt=""
          className={clsx(
            classes.imgStyle,
            isPortrait && classes.verticalImgStyle,
            isCoverOnly && classes.noOpenstyle
          )}
        />
        {isSliderVisible && (
          <div className={classes.thumbContainerStyle}>
            {isArrowsVisible && (
              <>
                <div
                  className={clsx(classes.iconWrapperStyle, classes.leftStyle)}
                >
                  <IconLeft
                    className={classes.iconStyle}
                    data-name={Direction.LEFT}
                    onClick={onArrowClick}
                  />
                </div>
                <div
                  className={clsx(classes.iconWrapperStyle, classes.rightStyle)}
                >
                  <IconRight
                    className={classes.iconStyle}
                    data-name={Direction.RIGHT}
                    onClick={onArrowClick}
                  />
                </div>
              </>
            )}
            <ImageSlider data={items} openImage={openGallery} ref={childRef} />
          </div>
        )}
        <div className={classes.storyShareStyle}>
          <Share
            title={title}
            text={intro}
            image={currentImageData}
            data={data}
          />
        </div>
      </div>
      {isGalleryVisible && getGallery()}
    </div>
  );
};

export default Story;
