import * as C from '../common/constants';
import React, { useEffect } from 'react';
import SelectionBlock from './SelectionBlock';
import { COLORS } from '../common/theme';
import { RequestID, Color } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      mainBlocksContainerStyle: {
        position: 'relative',
        width: '100%',
        minHeight: `${C.MAIN_BLOCKS_MAX_HEIGHT}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '60px',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      },
      backgroundStyle: {
        position: 'absolute',
        width: '100%',
        height: `${C.MAIN_BLOCKS_MAX_HEIGHT}px`,
        background: COLORS.backgroundGray,
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '0 30px',
        },
      },
      [`@media (max-width: 1110px)`]: {
        backgroundStyle: {
          height: '900px',
        },
      },
      [`@media (max-width: 800px)`]: {
        wrapperStyle: {
          justifyContent: 'center',
        },
      },
    })
);

const MainBlocks = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { [RequestID.MAIN]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.MAIN));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return null;
  }

  const { pages } = currentData;

  const onBlockOpen = (
    event: React.SyntheticEvent,
    uri: string,
    link?: string
  ) => {
    event.stopPropagation();
    event.preventDefault();

    // open direct links in new tab
    if (link) {
      return window.open(link, '_blank');
    }

    return uri && history.push(`/${uri}`);
  };

  return (
    <div className={classes.mainBlocksContainerStyle}>
      <div className={classes.backgroundStyle} />
      <div className={classes.wrapperStyle}>
        {pages.map(({ content, images }, index) => (
          <SelectionBlock
            content={content}
            images={images}
            key={index}
            onClick={onBlockOpen}
            backgroundColor={Color.GRAY}
          />
        ))}
      </div>
    </div>
  );
};

export default MainBlocks;
