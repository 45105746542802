export enum Lng {
  LV = 'lv',
  EN = 'en',
}

export enum EmitStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ActionType {
  GET = 'GET',
  SEND = 'SEND',
}

export enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum RequestID {
  HEADER = 'header',
  PICTO = 'info',
  MAIN = 'resursi',
  TIMELINE = 'laika-linija',
  FOOTER = 'footer',

  CONTACTS = 'contacts',
  SECURITY = 'security',

  NOT_FOUND = 'not-found',

  NEWS = 'info/aktuali',

  SEARCH = 'search',
  TAGS = 'tags',

  NO_SEARCH = 'no-search',
  COOKIES = 'cookies',

  MESSENGER = 'muzeja-zinnesis',
}

export enum BlockType {
  BIG = 'BIG',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export enum Color {
  GRAY = 'gray',
}

export enum Orientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}
