import * as C from '../constants';
import { get } from '../utils/requests';
import { getPath } from '../utils/helpers';

export function storeData(payload: any, type: string) {
  return {
    payload,
    type,
  };
}

export function fetchData(uri: string, pageNr = 1) {
  return (dispatch, getState) => {
    const data = getState().api.data;
    const lng = getState().app.lng;
    const currentData = data[uri];

    if (
      currentData &&
      (currentData?.totalItems === currentData?.pages?.length ||
        currentData?.pageNr > pageNr)
    ) {
      return currentData;
    }

    const urlPrefix = getPath();
    const url = `${urlPrefix}api?section=${uri}&page=${pageNr}&lng=${lng}`;

    return get(url)
      .then(({ data }) => {
        return dispatch(storeData(data, uri));
      })
      .catch((error) => {
        // const errorData: IError = error.response && error.response.data;
        // return dispatch(throwGlobalError(errorData));
      });
  };
}

export function clearSearchData() {
  return {
    type: C.CLEAR_SEARCH_DATA,
  };
}

export function fetchSearchData(searchString: string, pageNr = 1) {
  return (dispatch, getState) => {
    const lng = getState().app.lng;
    if (pageNr === 1) {
      dispatch(clearSearchData());
    }

    const urlPrefix = getPath();
    const url = `${urlPrefix}api?section=search&${searchString}&page=${pageNr}&lng=${lng}`;

    return get(url)
      .then(({ data }) => {
        return dispatch(storeData(data, 'search'));
      })
      .catch((error) => {
        // const errorData: IError = error.response && error.response.data;
        // return dispatch(throwGlobalError(errorData));
      });
  };
}
