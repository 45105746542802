import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import HeaderSlider from './HeaderSlider';
import Language from './Language';
import React, { useEffect } from 'react';
import SearchInput from './SearchInput';
import headerBg from '../assets/img/header.jpg';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RequestID } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      headerContainerStyle: {
        position: 'fixed',
        width: '100%',
        height: `${C.HEADER_HEIGHT}px`,
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px 3px 6px rgba(0,0,0,.16)',
        background: COLORS.white,
        zIndex: 10,
      },
      headerSliderStyle: {
        position: 'absolute',
        width: '370px',
        height: `${C.HEADER_HEIGHT}px`,
        top: 0,
        left: 0,
        zIndex: 1000,
        // pointerEvents: 'none',
      },
      headerWrapperStyle: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        height: '100%',
      },
      titleContainerStyle: {
        position: 'absolute',
        marginBottom: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      titleStyle: {
        color: COLORS.black,
        fontWeight: FONT_WEIGHT.bold,
        fontSize: '60px',
        lineHeight: '60px',
        fontFamily: FONTS.alegreya,
        '&::first-letter': {
          color: COLORS.main,
        },
      },
      descriptionStyle: {
        color: COLORS.secondary,
        fontWeight: FONT_WEIGHT.regular,
        fontSize: '26px',
        fontFamily: FONTS.alegreya,
        textAlign: 'center',
      },
      imgWrapperStyle: {
        position: 'absolute',
        maxHeight: `${C.HEADER_HEIGHT}px`,
        overflow: 'hidden',
      },
      imgStyle: {
        objectFit: 'cover',
      },
      searchContainerStyle: {
        // position: 'absolute',
        // bottom: 0,
        // right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '230px',
        width: '100%',
        marginLeft: 'auto',
        // height: '100%',
        // alignSelf: 'flex-end',
        // justifySelf: 'flex-end',
      },
      languageContainerStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        padding: '10px 0',
      },
      [`@media (max-width: 1230px)`]: {
        searchContainerStyle: {
          margin: '0 30px 0 auto',
        },
        languageContainerStyle: {
          margin: '0 auto 0 30px',
        },
      },
      [`@media (max-width: 900px)`]: {
        headerSliderStyle: {
          display: 'none',
        },
        headerWrapperStyle: {
          justifyContent: 'space-between',
        },
        titleContainerStyle: {
          margin: '0 30px',
        },
        titleStyle: {
          fontSize: '50px',
          lineHeight: '50px',
        },
        descriptionStyle: {
          fontSize: '20px',
        },
        languageContainerStyle: {
          padding: 0,
        },
      },
      [`@media (max-width: 600px)`]: {
        headerSliderStyle: {
          display: 'none',
        },
        headerWrapperStyle: {
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > div': {
            position: 'relative',
          },
        },
        headerContainerStyle: {
          height: '200px',
        },
        titleContainerStyle: {
          margin: '10px 30px 0 30px',
        },
        searchContainerStyle: {
          maxWidth: 'unset',
          margin: '0 0 20px 0',
          padding: '0 30px',
        },
      },
    })
);

const Header = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lng = useSelector((state) => state.app.lng);
  const { [RequestID.HEADER]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.HEADER));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return null;
  }

  const { content, images } = currentData;
  const title: string = getText(content, 'title', lng);
  const description: string = getText(content, 'text', lng);

  const goHome = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    history.push({ pathname: ROUTES.ROOT, search });
  };

  const onHeaderSliderClick = () => {
    history.push('/resursi/galerijas-un-virtualas-izstades/galerijas/tuvplani');
  };

  return (
    <div className={classes.headerContainerStyle}>
      <div className={classes.imgWrapperStyle}>
        <img src={headerBg} alt="" className={classes.imgStyle} />
      </div>
      <div className={classes.headerWrapperStyle}>
        {Boolean(images) && (
          <div
            className={classes.headerSliderStyle}
            onClick={onHeaderSliderClick}
          >
            <HeaderSlider images={images} />
          </div>
        )}
        <div className={classes.titleContainerStyle} onClick={goHome}>
          <h1 className={classes.titleStyle}>{title}</h1>
          <h2 className={classes.descriptionStyle}>{description}</h2>
        </div>
        <div className={classes.languageContainerStyle}>
          <Language />
        </div>
        <div className={classes.searchContainerStyle}>
          <SearchInput />
        </div>
      </div>
    </div>
  );
};

export default Header;
