import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { useHistory } from 'react-router-dom';
import { Lng } from '../common/enums';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const MAX_TAGS = 30;

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      searchTagsContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
      },
      titleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '20px',
      },
      tagsContainerStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '12px',
        '& > div': {
          margin: '2px 6px 2px 0',
        },
      },
      tagStyle: {
        color: COLORS.textGray,
        maxHeight: '27px',
        fontWeight: FONT_WEIGHT.bold,
        fontSize: '11px',
        fontFamily: FONTS.garamond,
        background: COLORS.backgroundGray,
        textTransform: 'uppercase',
        padding: '6px 10px',
        marginRight: '6px',
        borderRadius: '2px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: COLORS.primary,
            boxShadow: '0px 0px 3px rgba(0,0,0,.16)',
            transition: '0.5s ease',
          },
        },
      },
    })
);

const SearchTags = ({ data }): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const lng = useSelector((state) => state.app.lng);

  const tagsCounts = () => {
    const counts = {};
    data.forEach((x) => {
      counts[x] = (counts[x] || 0) + 1;
    });

    return counts;
  };

  const currentTags = tagsCounts();

  const sortable = [];
  for (const tags in currentTags) {
    sortable.push([tags, currentTags[tags]]);
  }

  const sortedTags = sortable.sort((a, b) => {
    return b[1] - a[1];
  });

  // limit search tags to X entries
  const displayTags = sortedTags.slice(0, MAX_TAGS);

  const onTagClick = (tag) => {
    history.push({
      pathname: '/search',
      search: `?tags=${tag}`,
    });
  };

  const tagsLabel: string = lng === Lng.LV ? 'Atslēgvārdi' : 'Tags';

  return (
    <div className={classes.searchTagsContainerStyle}>
      <div className={classes.titleStyle}>{tagsLabel}</div>
      <div className={classes.tagsContainerStyle}>
        {displayTags.map(([name, count], index) => {
          const displayName = `${name} (${count})`;

          return (
            <div
              key={index}
              className={classes.tagStyle}
              onClick={() => onTagClick(name)}
            >
              {displayName}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchTags;
