import * as C from '../common/constants';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import ReactPlayer from 'react-player';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as CloseIcon } from '../assets/svg/cancel_circle.svg';
import { getText, getPath } from '../common/utils/helpers';
import { useSelector } from 'react-redux';
import { Orientation } from '../common/enums';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface Props {
  onClose?: (event?: React.SyntheticEvent) => void;
  data: any;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      dialogContainerStyle: {
        '& > div > div': {
          maxWidth: `${C.MAX_WIDTH}px`,
          width: '100%',
          background: COLORS.white,
        },
      },
      audioContainerStyle: {
        '& > div > div': {
          maxWidth: `${C.MAX_WIDTH}px`,
          width: '100%',
          // minHeight: '200px',
          background: COLORS.white,
        },
      },
      closeButtonStyle: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        fill: COLORS.textGray,
        width: '40px',
        height: '40px',
        pointerEvents: 'auto',
        zIndex: 100,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      dialogContentStyle: {
        margin: '16px',
        color: COLORS.textBlack,
      },
      titleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '20px',
        marginBottom: '20px',
      },
      textStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '16px',
        textAlign: 'justify',
      },
      introStyle: {
        marginTop: '12px',
        fontWeight: FONT_WEIGHT.bold,
      },
      videoStyle: {},
      '@global': {
        '.ytp-pause-overlay': {
          display: 'none !important',
        },
        '.ytp-chrome-top-buttons': {
          display: 'none !important',
        },
        '.ytp-watch-later-button': {
          display: 'none !important',
        },
        '.ytp-share-button': {
          display: 'none !important',
        },
      },
      imgContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      imgStyle: {
        // width: '570px',
        height: '500px',
        objectFit: 'cover',
        border: `1px solid ${COLORS.circleGray}`,
        backgroundColor: COLORS.circleGray,
      },
      verticalImgStyle: {
        objectFit: 'contain',
      },
      [`@media (max-width: 900px)`]: {
        '@global': {
          '.MuiDialog-paper': {
            margin: '0 !important',
          },
        },
      },
    })
);

const VideoDialog = ({ onClose, data }: Props) => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const {
    content,
    content: { cover },
    audio,
    images,
  } = data;

  const title: string = getText(content, 'title', lng);
  const intro: string = getText(content, 'intro', lng);
  const text: string = getText(content, 'text', lng);

  const isAudio: boolean = !!audio?.length;

  const urlPrefix = getPath();
  const coverImageData =
    cover && images?.find((item) => cover.includes(item.filename));
  const currentImageData = coverImageData || images?.[0];
  const currentImg: string = `${urlPrefix}${currentImageData?.url}`;
  const isPortrait =
    currentImageData?.dimensions?.orientation === Orientation.PORTRAIT;

  const getPlayer = () => {
    if (isAudio) {
      const urlPrefix = getPath();
      const audioUrl = `${urlPrefix}${audio[0].url}`;

      return (
        <ReactPlayer
          url={audioUrl}
          width="100%"
          height="70px"
          playing={true}
          controls={true}
          className={classes.videoStyle}
          // Disable download button
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          // Disable right click
          onContextMenu={(e) => e.preventDefault()}
        />
      );
    }

    const videoUrl: string = getText(content, 'link', lng);

    return (
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="500px"
        config={{
          youtube: {
            playerVars: { showinfo: 0, autoplay: 1, controls: 1 },
          },
        }}
        className={classes.videoStyle}
      />
    );
  };

  const isMobile = window.innerWidth < 900;

  return (
    <div>
      <Dialog
        fullScreen={isMobile}
        open={true}
        onClose={onClose}
        className={clsx(
          classes.dialogContainerStyle,
          isAudio && classes.audioContainerStyle
        )}
      >
        <CloseIcon className={classes.closeButtonStyle} onClick={onClose} />
        <MuiDialogContent className={classes.dialogContentStyle}>
          <div className={classes.titleStyle}>{title}</div>
          {isAudio && (
            <div className={classes.imgContainerStyle}>
              <img
                src={currentImg}
                alt=""
                className={clsx(
                  classes.imgStyle,
                  isPortrait && classes.verticalImgStyle
                )}
              />
            </div>
          )}
          {getPlayer()}
          <div
            className={clsx(classes.textStyle, classes.introStyle)}
            dangerouslySetInnerHTML={{
              __html: intro,
            }}
          />
          <div
            className={classes.textStyle}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </MuiDialogContent>
      </Dialog>
    </div>
  );
};

export default VideoDialog;
