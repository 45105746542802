import * as ROUTES from '../common/routes';
import React from 'react';
import clsx from 'clsx';
import { COLORS } from '../common/theme';
import { ReactComponent as IconBack } from '../assets/svg/back-button.svg';
import { ReactComponent as IconHome } from '../assets/svg/home.svg';
import { useHistory } from 'react-router-dom';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface Props {
  onBack?: () => void;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      backContainerStyle: {
        display: 'flex',
        position: 'fixed',
      },
      iconWrapperStyle: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: `1px solid ${COLORS.textGray}`,
        background: COLORS.textGray,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconStyle: {
        width: '20px',
      },
      homeStyle: {
        marginRight: '10px',
      },
    })
);

const Back = ({ onBack }: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const goHome = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    history.push(ROUTES.ROOT);
  };

  const onBackHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (onBack) {
      return onBack();
    }

    history.goBack();
  };

  return (
    <div className={classes.backContainerStyle}>
      <div
        className={clsx(classes.iconWrapperStyle, classes.homeStyle)}
        onClick={goHome}
      >
        <IconHome className={classes.iconStyle} />
      </div>
      <div className={classes.iconWrapperStyle} onClick={onBackHandler}>
        <IconBack className={classes.iconStyle} />
      </div>
    </div>
  );
};

export default Back;
