import * as C from '../common/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHeader from './PageHeader';
import React, { useEffect } from 'react';
import SelectionBlock from './SelectionBlock';
import { BlockType } from '../common/enums';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { fetchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      blocksContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '60px',
        flexGrow: 1,
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 0',
      },
      itemsContainerStyle: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      textStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '16px',
        marginTop: '10px',
        '& a:link': {
          color: COLORS.primary,
          textDecoration: 'none',
        },
        '& a:visited': {
          color: COLORS.primary,
        },
        '& a:hover': {
          color: COLORS.textBlack,
        },
      },
      [`@media (max-width: 740px)`]: {
        itemsContainerStyle: {
          justifyContent: 'center',
        },
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
    })
);

const BlocksWrapper = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const lng = useSelector((state) => state.app.lng);

  const id = location.pathname;
  const { [id]: currentData } = useSelector((state) => state.api.data);

  useEffect(() => {
    dispatch(fetchData(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  const { pages, content } = currentData;
  const title: string = getText(content, 'title', lng);
  const text: string = getText(content, 'text', lng);

  const onBlockOpen = (
    event: React.SyntheticEvent,
    uri: string,
    link?: string
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (uri) {
      history.push(`/${uri}`);
      // history.go();
    }
  };

  const blockType = pages.length > 2 ? BlockType.MEDIUM : BlockType.BIG;

  return (
    <div className={classes.blocksContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader title={title} />
        {text && (
          <div
            className={classes.textStyle}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
        <div className={classes.itemsContainerStyle}>
          {pages.map(({ content, images }, index) => (
            <SelectionBlock
              content={content}
              images={images}
              key={index}
              onClick={onBlockOpen}
              blockType={blockType}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlocksWrapper;
