import Popover from '@material-ui/core/Popover';
import React, { useState } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng } from '../common/enums';
import { useHistory, useLocation } from 'react-router-dom';
import { useOverflow } from '../hooks';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  tags_lv: string;
  tags_en: string;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      tagsContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '45px',
        overflow: 'hidden',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(90deg, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%)',
          pointerEvents: 'none',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'flex-end',
          // fontSize: '40px',
          // lineHeight: '20px',
        },
      },
      tagsWrapperStyle: {
        position: 'relative',
        width: '100%',
        height: '45px',
      },
      notOverflowStyle: {
        // position: 'relative',
        // width: '100%',
        // height: '45px',
        // '&:after': {
        //   content: '""',
        //   position: 'absolute',
        //   top: 0,
        //   right: 0,
        //   width: '100%',
        //   height: '100%',
        //   background: 'none',
        // },
      },
      tagsContentStyle: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        margin: '12px 0 6px 0',
      },
      allTagsStyle: {
        position: 'relative',
        // width: '800px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '8px',
        '& > div': {
          margin: '2px 6px 2px 0',
        },
      },
      tagStyle: {
        color: COLORS.textGray,
        maxHeight: '27px',
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '11px',
        fontFamily: FONTS.garamond,
        background: COLORS.backgroundGray,
        textTransform: 'uppercase',
        padding: '6px 10px',
        marginRight: '6px',
        borderRadius: '2px',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: COLORS.primary,
            boxShadow: '0px 0px 3px rgba(0,0,0,.16)',
            transition: '0.5s ease',
          },
        },
      },
      moreStyle: {
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textGray,
        margin: '2px 10px 0 0',
        textTransform: 'uppercase',
      },
    })
);

const Tags = ({ tags_lv, tags_en }: IProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const lng = useSelector((state) => state.app.lng);

  const isEng: boolean = lng !== Lng.LV;
  const rawTags = isEng ? tags_en : tags_lv;

  const horizonstalRef = React.useRef(null);
  const { refXOverflowing: isOverflowing } = useOverflow(horizonstalRef);

  const [anchorEl, setAnchorEl] = useState(null);
  const tags = rawTags.split(', ');

  const onTagClick = (tag) => {
    history.push({
      pathname: '/search',
      search: `?tags=${tag}`,
    });
  };

  const onTagContainerClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTags = (isClickEnabled?: boolean) => {
    return tags.map((tag, index) => {
      const tagClickProps = {
        ...(!isOverflowing || isClickEnabled
          ? { onClick: () => onTagClick(tag) }
          : {}),
      };

      return (
        <div key={index} className={classes.tagStyle} {...tagClickProps}>
          {tag}
        </div>
      );
    });
  };

  const getAllTags = () => {
    return <div className={classes.allTagsStyle}>{getTags(true)}</div>;
  };

  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? 'simple-popover' : undefined;

  const tagContainerClickProps = {
    ...(isOverflowing ? { onClick: onTagContainerClick } : {}),
  };

  const MORE_TAGS: string = lng === Lng.LV ? 'Vēl: ' : 'More: ';

  return (
    <div
      className={clsx(
        classes.tagsContainerStyle,
        !isOverflowing && classes.notOverflowStyle
      )}
    >
      <div
        className={classes.tagsContentStyle}
        ref={horizonstalRef}
        {...tagContainerClickProps}
      >
        <div className={classes.moreStyle}>{MORE_TAGS}</div>
        {getTags()}
      </div>
      <Popover
        id={id}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {getAllTags()}
      </Popover>
    </div>
  );
};

export default Tags;
