import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RequestID, BlockType, Color, Orientation } from '../common/enums';
import { getPath, getText } from '../common/utils/helpers';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      blockStyle: {
        position: 'relative',
        width: '370px',
        height: '240px',
        marginTop: '30px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
        fontSize: '20px',
        lineHeight: '24px',
        // '&:last-child': {
        //   marginRight: 'auto',
        // },
      },
      bigBlockStyle: {
        width: '570px',
        height: '370px',
        fontSize: '24px',
        lineHeight: '28px',
      },
      imgStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: `1px solid ${COLORS.circleGray}`,
        backgroundColor: COLORS.circleGray,
      },
      verticalImgStyle: {
        objectFit: 'contain',
      },
      titleStyle: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        boxSizing: 'border-box',
        maxWidth: '300px',
        minHeight: '60px',
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        background: COLORS.white85,
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: '20px',
        padding: '10px 10px 0 0',
      },
      bigTitleStyle: {
        maxWidth: '470px',
      },
      grayStyle: {
        background: COLORS.backgroundGray85,
      },
    })
);

interface Props {
  content: any;
  images: any;
  onClick: (event: React.SyntheticEvent, id?: RequestID, link?: string) => void;
  blockType?: BlockType;
  backgroundColor?: Color;
}

const SelectionBlock = ({
  content,
  images = [],
  onClick,
  blockType,
  backgroundColor,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);

  const title: string = getText(content, 'title', lng);
  const link: string = getText(content, 'link', lng);
  const urlPrefix = getPath();

  const coverName = content?.cover;
  const coverImageData =
    coverName && images.find((item) => coverName.includes(item.filename));
  const currentImageData = coverImageData || images[0];
  const currentImg: string = `${urlPrefix}${currentImageData?.url}`;

  const isBigBlock: boolean = blockType === BlockType.BIG;
  const isGray: boolean = backgroundColor === Color.GRAY;

  const isPortrait =
    currentImageData?.dimensions?.orientation === Orientation.PORTRAIT;

  return (
    <div
      className={clsx(
        classes.blockStyle,
        isBigBlock && classes.bigBlockStyle,
        isGray && classes.grayStyle
      )}
      onClick={(e) => onClick(e, content.uri, link)}
    >
      {currentImageData ? (
        <img
          src={currentImg}
          alt=""
          className={clsx(
            classes.imgStyle,
            isPortrait && classes.verticalImgStyle
          )}
        />
      ) : (
        <div className={classes.imgStyle} />
      )}
      <div
        className={clsx(
          classes.titleStyle,
          isBigBlock && classes.bigTitleStyle,
          isGray && classes.grayStyle
        )}
      >
        {title}
      </div>
    </div>
  );
};

export default SelectionBlock;
