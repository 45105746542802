import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng } from '../common/enums';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      moreWrapperStyle: {
        width: '100%',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      moreStyle: {
        width: '130px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${COLORS.primary}`,
        backgroundColor: COLORS.white,
        borderRadius: '6px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
        '& > span': {
          whiteSpace: 'nowrap',
          fontFamily: FONTS.alegreya,
          fontWeight: FONT_WEIGHT.medium,
          color: COLORS.primary,
          fontSize: '16px',
          textTransform: 'uppercase',
          marginTop: '2px',
        },
      },
    })
);

const LoadMore = ({ data, onLoadMore }): JSX.Element => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const MORE_TEXT: string = lng === Lng.LV ? 'Skatīt vairāk' : 'Read more';

  const [isLoading, setLoadingMore] = useState(false);

  useEffect(() => {
    setLoadingMore(false);
  }, [data?.pages]);

  const onClick = () => {
    setLoadingMore(true);
    onLoadMore();
  };

  return (
    <div className={classes.moreWrapperStyle}>
      {isLoading ? (
        <div className={classes.loaderStyle}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.moreStyle} onClick={onClick}>
          <span>{MORE_TEXT}</span>
        </div>
      )}
    </div>
  );
};

export default LoadMore;
