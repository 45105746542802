import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import scrollParentToChildPos from '../common/utils/scrollTo';
import clsx from 'clsx';
import { COLORS } from '../common/theme';
import { Direction } from '../common/enums';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  data: any[];
  openImage: (index: number) => void;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      baseStyle: {
        position: 'absolute',
        maxWidth: '470px',
        width: '100%',
        height: '140px',
        overflow: 'hidden',
        overflowX: 'scroll',
        userSelect: 'none',
        touchAction: 'pan-x',
      },
      viewStyle: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
      },
      photoIconStyle: {
        position: 'relative',
        marginRight: '10px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        border: `1px solid ${COLORS.circleGray}`,
        boxSizing: 'border-box',
      },
      thumbStyle: {
        width: 'auto',
        height: 'auto',
        objectFit: 'contain',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      thumbActiveStyle: {
        boxShadow: '0px 0px 12px 5px rgba(0,0,0,.30)',
      },
    })
);

const ImageSlider = forwardRef((props: IProps, ref) => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(1);

  const scroller = {};
  const { data, openImage } = props;
  const max: number = data.length;

  useEffect(() => {
    scrollParentToChildPos(scroller[`scrollel${currentImage}`], 2);
  }, [currentImage]);

  useImperativeHandle(ref, () => ({
    goTo(direction: Direction) {
      if (direction === Direction.RIGHT) {
        const imageNr = currentImage + 1;

        if (imageNr <= max) {
          setCurrentImage(imageNr);
        }
      } else {
        const imageNr = currentImage - 1;
        if (imageNr >= 1) {
          setCurrentImage(imageNr);
        }
      }
    },
  }));

  const renderStep = (i: number) => {
    const thumbUrl = data[i].thumbnail;
    const isActive: boolean = i + 1 === currentImage;

    return (
      <div
        key={i}
        className={classes.photoIconStyle}
        onClick={(e) => openImage(i + 1)}
        ref={(e) => (scroller[`scrollel${i}`] = e)}
      >
        <img
          className={clsx(
            classes.thumbStyle,
            isActive && classes.thumbActiveStyle
          )}
          src={thumbUrl}
          alt=""
        />
      </div>
    );
  };

  const renderSteps = () => {
    return [...Array(max)].map((el, i) => renderStep(i));
  };

  return (
    <div className={clsx(classes.baseStyle, 'swiperSlider')}>
      <div className={classes.viewStyle}>{renderSteps()}</div>
    </div>
  );
});

export default ImageSlider;
