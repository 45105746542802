import * as C from '../common/constants';
import React from 'react';
import PictoBlocks from './PictoBlocks';
import Timeline from './Timeline';
import MainBlocks from './MainBlocks';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      homeContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        flexGrow: 1,
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
      },
    })
);

const Home = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.homeContainerStyle}>
      <PictoBlocks />
      <MainBlocks />
      <Timeline />
    </div>
  );
};

export default Home;
