import * as C from '../common/constants';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { ReactComponent as IconEmail } from '../assets/svg/email.svg';
import { ReactComponent as IconFacebook } from '../assets/svg/facebook.svg';
import { ReactComponent as IconLink } from '../assets/svg/link.svg';
import { ReactComponent as IconShare } from '../assets/svg/share.svg';
import { ReactComponent as IconTwitter } from '../assets/svg/twitter.svg';
import { getPath } from '../common/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { Lng } from '../common/enums';
import { Helmet } from 'react-helmet';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  title?: string;
  text?: string;
  image?: any;
  data?: any;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      shareContainerStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
      },
      labelStyle: {
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'right',
        marginRight: '10px',
        pointerEvents: 'none',
        userSelect: 'none',
      },
      iconWrapperStyle: {
        width: '40px',
        height: '40px',
        minWidth: '40px',
        minHeight: '40px',
        borderRadius: '50%',
        border: `1px solid ${COLORS.textGray}`,
        background: COLORS.textGray,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
        marginRight: '10px',
      },
      iconStyle: {
        width: '20px',
      },
      lastStyle: {
        marginRight: 0,
      },
    })
);

const Share = ({ title, text, image, data }: IProps): JSX.Element => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);

  const uri = data?.content?.uri || '';
  const url = `${C.EMUZEJS_URL}/${uri}`;
  // const imageUrl = `${C.EMUZEJS_URL}/admin${image?.url}`;

  const shareLabel: string = lng === Lng.LV ? 'Ieteikt citiem' : 'Share';

  const onEmailClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    window.open(`mailto:?subject=${title}&body=${text} ${url}`, '_blank');
  };

  return (
    <div className={classes.shareContainerStyle}>
      {/* <Helmet>
          <meta property="og:url" content={url} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={text} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:secure_url" content={imageUrl} />
        </Helmet> */}
      <span className={classes.labelStyle}>{shareLabel}</span>
      <FacebookShareButton url={url} quote={`${title} - ${text}`}>
        <div className={classes.iconWrapperStyle}>
          <IconFacebook className={classes.iconStyle} />
        </div>
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        via={'eMuzejs'}
        related={['LNVMuzejs']}
      >
        <div className={classes.iconWrapperStyle}>
          <IconTwitter className={classes.iconStyle} />
        </div>
      </TwitterShareButton>
      <div
        className={clsx(classes.iconWrapperStyle, classes.lastStyle)}
        onClick={onEmailClick}
      >
        <IconEmail className={classes.iconStyle} />
      </div>
      {/* <div className={classes.iconWrapperStyle}>
        <IconLink className={classes.iconStyle} />
      </div> */}
    </div>
  );
};

export default Share;
