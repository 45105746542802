import React, { useState, useRef, useEffect } from 'react';
import { COLORS, FONTS } from '../common/theme';
import { getPath, getText } from '../common/utils/helpers';
import { gsap } from 'gsap';
import { useSelector } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  images: any;
}

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      footerImageContainerStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        pointerEvents: 'none',
        marginLeft: 'auto',
      },
      footerImageWrapperStyle: {
        width: '200px',
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // overflow: 'hidden',
      },
      imgStyle: {
        // width: '100%',
        // height: '100%',
        width: '200px',
        height: '120px',
        objectFit: 'cover',
      },
      textStyle: {
        maxWidth: '200px',
        marginRight: '14px',
        textAlign: 'right',
        color: COLORS.white,
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: FONTS.alegreya,
        '& b:after': {
          content: '',
          width: '100%',
          position: 'absolute',
          left: 0,
          bottom: '5px',
        },
      },
      [`@media (max-width: 900px)`]: {
        textStyle: {
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
      [`@media (max-width: 400px)`]: {
        footerImageContainerStyle: {
          flexDirection: 'column-reverse',
        },
        textStyle: {
          margin: '14px 0 0 0',
        },
      },
    })
);

const FooterSlider = ({ images }: IProps): JSX.Element => {
  const classes = useStyles();
  const footerImage = useRef(null);
  const lng = useSelector((state) => state.app.lng);
  const [currentImageNr, setImageNr] = useState(0);

  const currentImage = images?.[currentImageNr];
  const imgUrl: string = `${urlPrefix}${currentImage?.url}`;

  const rawText: string = getText(currentImage?.content, 'text', lng);
  const text = rawText?.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const onRepeat = () => {
    const newNr = currentImageNr >= images.length - 1 ? 0 : currentImageNr + 1;

    setImageNr(newNr);
  };

  useEffect(() => {
    gsap.fromTo(
      footerImage.current,
      {
        opacity: 0,
        // scale: 1,
      },
      {
        // scale: 1.3,
        opacity: 1,
        duration: 3,
        repeat: 1,
        yoyo: true,
        onComplete: onRepeat,
      }
    );
  }, [currentImageNr]);

  return (
    <div className={classes.footerImageContainerStyle}>
      <div
        className={classes.textStyle}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <div className={classes.footerImageWrapperStyle} ref={footerImage}>
        <img src={imgUrl} alt="" className={classes.imgStyle} />
      </div>
    </div>
  );
};

export default FooterSlider;
