import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const COLORS = {
  main: '#0B0B61',
  primary: '#0B0B61',
  secondary: '#3A3A3A',
  white: '#FFFFFF',
  white85: 'rgba(255,255,255,0.85)',
  red: 'red',
  green: 'green',
  textBlack: '#4a4a4a',
  textGray: '#999999',
  textDescription: '#333333',
  background: '#009ab9',
  backgroundGray: '#f4f4f4',
  backgroundGray85: 'rgba(244,244,244,0.85)',
  backgroundGray65: 'rgba(244,244,244,0.65)',
  circleGray: '#E6E6E6',
  black: '#000000',
  socialCircleGray: '#666666',
  previewGray: '#666666',
  black80: 'rgba(0,0,0,0.8)',
};

export const FONTS = {
  alegreya: 'Alegreya Sans',
  garamond: 'EB Garamond',
};

export const FONT_WEIGHT = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const SIZES = {
  maxSearchBlockWidth: 440,
};

// const fontFamily: string = ['"Alegreya Sans"', 'Arial', 'sans'].join(',');

export const getTheme = () => {
  return createMuiTheme({
    palette: {
      // primary: { main: COLORS.main },
      // secondary: { main: COLORS.secondary },
      // background: {
      //   paper: COLORS.background,
      // },
      // type: 'dark',
    },
    typography: {
      // fontFamily,
      // fontWeightRegular: 400,
      // fontWeightMedium: 500,
      // fontWeightBold: 700,
      // h2: {
      //   fontSize: '50px',
      // },
      h3: {
        // verticalAlign: 'middle',
        // alignItems: 'center',
        // textAlign: 'center',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '20px',
        color: COLORS.textBlack,
        textTransform: 'uppercase',
      },
      // h1: {
      //   fontSize: '86px',
      //   color: COLORS.white,
      //   verticalAlign: 'middle',
      //   alignItems: 'center',
      //   textAlign: 'center',
      // },
    },
    // overrides: {
    //   MuiCssBaseline: {
    //     '@global': {
    //       '@font-face': [alegreyaSans, ebGaramond],
    //     },
    //   },
    // },
  });
};
