import * as C from '../common/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHeader from './PageHeader';
import React, { useEffect } from 'react';
import { Lng, RequestID } from '../common/enums';
import { TagCloud } from 'react-tagcloud';
import { fetchData } from '../common/api/actions';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { COLORS } from '../common/theme';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      tagContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        flex: '1 1 auto',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      tagWrapperStyle: {
        marginTop: '30px',
        textAlign: 'center',
        lineHeight: 1.2,
      },
      '@global': {
        '.tag-cloud-tag': {
          color: COLORS.textBlack,
          cursor: 'pointer',
          '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
              color: COLORS.primary,
              transition: '0.5s ease',
            },
          },
        },
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
    })
);

const TagsPage = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);

  const { pageId } = useParams();

  const { [RequestID.TAGS]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.TAGS));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  const { content } = currentData;

  const getTags = () => {
    const currentTagsArray = content[pageId + '_' + lng]?.split(', ');

    return currentTagsArray.map((item) => {
      return {
        value: item,
        count: Math.floor(Math.random() * 100) + 1,
      };
    });
  };

  const onClick = (tag) => {
    history.push({
      pathname: '/search',
      search: `?tags=${tag.value}`,
    });
  };

  const tags = getTags();

  const tagsLabel: string = lng === Lng.LV ? 'Atslēgvārdi' : 'Tags';

  return (
    <div className={classes.tagContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader title={tagsLabel} />
        <div className={classes.tagWrapperStyle}>
          <TagCloud
            minSize={10}
            maxSize={50}
            tags={tags}
            onClick={onClick}
            disableRandomColor={true}
            shuffle={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TagsPage;
