import * as C from '../common/constants';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng, RequestID } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { getText, getPath } from '../common/utils/helpers';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const dates = [1200, 1561, 1914, 1991];

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      timelineContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '360px',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
      },
      timeContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      upStyle: {
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: `${COLORS.textGray} dashed 1px`,
      },
      downStyle: {
        display: 'flex',
        flexDirection: 'row',
      },
      eraStyle: {
        position: 'relative',
        textAlign: 'center',
        width: '20%',
        fontSize: '20px',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        cursor: 'pointer',
        pointerEvents: 'auto',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      eraEngStyle: {
        fontSize: '16px',
      },
      currentEraStyle: {
        cursor: 'default',
        pointerEvents: 'none',
      },
      eraLineStyle: {
        position: 'absolute',
        width: '100%',
        bottom: '-2px',
      },
      currentEraLineStyle: {
        borderBottom: `${COLORS.textBlack} solid 3px`,
      },
      yearStyle: {
        position: 'relative',
        textAlign: 'center',
        width: '20%',
      },
      pointStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        right: '-15px',
        width: '30px',
      },
      dotStyle: {
        position: 'absolute',
        top: '-5px',
        backgroundColor: COLORS.textBlack,
        borderRadius: '50%',
        display: 'inline-block',
        width: '10px',
        height: '10px',
      },
      lineStyle: {
        position: 'absolute',
        width: '1px',
        height: '20px',
        backgroundColor: COLORS.textBlack,
      },
      dateStyle: {
        padding: '24px',
        fontSize: '20px',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
      },
      infoStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '80px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      imgStyle: {
        width: '370px',
        height: '240px',
      },
      infoWrapperStyle: {
        marginLeft: '30px',
      },
      titleStyle: {
        display: 'block',
        fontSize: '30px',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        marginBottom: '24px',
        lineHeight: '28px',
      },
      introStyle: {
        display: 'block',
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        color: COLORS.textBlack,
        textAlign: 'justify',
        marginBottom: '12px',
        lineHeight: '22px',
      },
      textStyle: {
        display: 'block',
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        color: COLORS.textBlack,
        textAlign: 'justify',
        lineHeight: '22px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      cursorStyle: {
        padding: '4px 8px',
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        color: COLORS.textBlack,
        border: `2px solid ${COLORS.circleGray}`,
        backgroundColor: COLORS.white,
        borderRadius: '20px',
        textTransform: 'uppercase',
      },
      [`@media (max-width: 1230px)`]: {
        imgStyle: {
          margin: '0 0 0 30px',
        },
        infoWrapperStyle: {
          margin: '30px',
        },
      },
      [`@media (max-width: 740px)`]: {
        infoStyle: {
          flexDirection: 'column',
          alignItems: 'center',
        },
        infoWrapperStyle: {
          margin: '30px',
        },
        imgStyle: {
          margin: 0,
        },
        upStyle: {
          height: 'unset',
          flexDirection: 'column',
          alignItems: 'center',
        },
        downStyle: {
          display: 'none',
        },
        eraStyle: {
          width: '180px',
          margin: '0 30px',
        },
      },
    })
);

const Timeline = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);
  const { [RequestID.TIMELINE]: currentData } = useSelector(
    (state) => state.api.data
  );

  const isEng: boolean = lng !== Lng.LV;
  const MORE_TEXT: string = lng === Lng.LV ? 'Skatīt vairāk' : 'Read more';

  const [currentEra, setEra] = useState(1);
  const [isHover, setHover] = useState(false);

  useEffect(() => {
    dispatch(fetchData(RequestID.TIMELINE));
  }, []);

  if (!currentData) {
    return null;
  }

  const { pages } = currentData;

  const getNames = ({ content, images }, index) => {
    const title: string = getText(content, 'title', lng);
    const isCurrent: boolean = currentEra === index + 1;
    return (
      <div
        key={index}
        className={clsx(
          classes.eraStyle,
          isCurrent && classes.currentEraStyle,
          isEng && classes.eraEngStyle
        )}
        onClick={() => setEra(index + 1)}
      >
        <div
          className={clsx(
            classes.eraLineStyle,
            isCurrent && classes.currentEraLineStyle
          )}
        />
        {title}
      </div>
    );
  };

  const getYears = (page, index) => {
    const year = dates[index];

    return (
      <div key={index} className={classes.yearStyle}>
        {!!year && (
          <div className={classes.pointStyle}>
            <div className={classes.dotStyle} />
            <div className={classes.lineStyle} />
            <div className={classes.dateStyle}>{year}</div>
          </div>
        )}
      </div>
    );
  };

  const currentEraData = pages[currentEra - 1];
  const urlPrefix = getPath();
  const currentImg: string = `${urlPrefix}${currentEraData.images[0].url}`;
  const currentContent = currentEraData.content;
  const currentTitle: string = getText(currentContent, 'title', lng);
  const currentIntro: string = getText(currentContent, 'intro', lng);
  const currentText: string = getText(currentContent, 'text', lng);
  const currentId = currentContent?.id;

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // history.push(`/search/?timeline=${currentId}`);
    history.push({
      pathname: '/search',
      search: `?timeline=${currentId}`,
      // state: { detail: response.data }
    });
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <div className={classes.timelineContainerStyle}>
      <div className={classes.wrapperStyle}>
        <div className={classes.timeContainerStyle}>
          <div className={classes.upStyle}>{pages.map(getNames)}</div>
          <div className={classes.downStyle}>{pages.map(getYears)}</div>
        </div>
        <div
          className={classes.infoStyle}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <img src={currentImg} alt="" className={classes.imgStyle} />
          <div className={classes.infoWrapperStyle}>
            <span className={classes.titleStyle}>{currentTitle}</span>
            <span className={classes.introStyle}>{currentIntro}</span>
            <span className={classes.textStyle}>{currentText}</span>
          </div>
        </div>
        <MouseTooltip visible={isHover} offsetX={15} offsetY={10}>
          <span className={classes.cursorStyle}>{MORE_TEXT}</span>
        </MouseTooltip>
      </div>
    </div>
  );
};

export default Timeline;
