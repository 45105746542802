import * as ROUTES from '../common/routes';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as SearchIcon } from '../assets/svg/search.svg';
import { RequestID, Lng } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const tags_lv = [
  { value: 'archeology', label: 'Arheoloģija' },
  { value: 'numismatics', label: 'Numismātika' },
  { value: 'ethnography', label: 'Etnogrāfija' },
  { value: 'history', label: 'Vēsture' },
];

const tags_en = [
  { value: 'archeology', label: 'Archeology' },
  { value: 'numismatics', label: 'Numismatics' },
  { value: 'ethnography', label: 'Ethnography' },
  { value: 'history', label: 'History' },
];

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      searchWrapperStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxHeight: '34px',
        width: '100%',
        height: '100%',
        border: `2px solid ${COLORS.circleGray}`,
        backgroundColor: COLORS.white,
        borderRadius: '20px',
        boxSizing: 'border-box',
        marginBottom: '4px',
      },
      searchInputStyle: {
        width: '100%',
        height: '24px',
        lineHeight: '24px',
        marginLeft: '10px',
        border: 'none',
      },
      iconWrapperStyle: {
        width: '36px',
        height: '36px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconStyle: {
        width: '20px',
        height: '20px',
        fill: COLORS.secondary,
      },
      bottomWrapperStyle: {
        // marginBottom: '12px',
      },
      [`@media (max-width: 1230px)`]: {
        searchContainerStyle: {
          marginRight: '30px',
        },
      },
    })
);

const SearchInput = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const lng = useSelector((state) => state.app.lng);
  const { input, tags } = queryString.parse(location?.search);
  const [searchInput, setSearchInput] = useState('');
  // const [currentTag, setTag] = useState(null);

  // const [suggestions, setSuggestions] = useState([]);

  // const { [RequestID.TAGS]: currentData } = useSelector(
  //   (state) => state.api.data
  // );

  const tagsList = lng === Lng.LV ? tags_lv : tags_en;

  useEffect(() => {
    // dispatch(fetchData(RequestID.TAGS));

    // if there is search input
    if (input) {
      setSearchInput(input);
    } else {
      // const currentPath = location.pathname;
      // const [, currentTagValue] = currentPath?.split('search/');
      // const currentTagOptions = tagsList.find(
      //   (item) => item.value === currentTagValue
      // );
      // setTag(currentTagOptions);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const getSuggestions = () => {
  //   const { content } = currentData;
  //   const archeology = content[`archeology_${lng}`]?.split(', ');
  //   const ethnography = content[`ethnography_${lng}`]?.split(', ');
  //   const history = content[`history_${lng}`]?.split(', ');
  //   const numismatics = content[`numismatics_${lng}`]?.split(', ');

  //   return [...archeology, ...ethnography, ...history, ...numismatics];
  // };

  // useEffect(() => {
  //   if (currentData) {
  //     const allSuggestions = getSuggestions();
  //     setSuggestions(allSuggestions);
  //   }
  // }, [currentData]);

  const onChange = (event) => {
    setSearchInput(event?.target?.value);
  };

  const onKeywordChange = ({ value, label }) => {
    history.push(`/search/${value}`);
  };

  const onSearch = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    // history.push(`/search/?input=${searchInput}`);
    history.push({
      pathname: '/search',
      search: `?input=${searchInput}`,
      // state: { detail: response.data }
    });
    // history.go();
  };

  const onSearchEnter = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const searchLabel: string = lng === Lng.LV ? 'Meklēt...' : 'Search...';
  const tagsLabel: string = lng === Lng.LV ? 'Atslēgvārdi' : 'Tags';

  return (
    <>
      <div className={classes.searchWrapperStyle}>
        <input
          type="search"
          placeholder={searchLabel}
          className={classes.searchInputStyle}
          onChange={onChange}
          value={searchInput}
          onKeyDown={onSearchEnter}
        />
        <div className={classes.iconWrapperStyle} onClick={onSearch}>
          <SearchIcon className={classes.iconStyle} />
        </div>
      </div>
      <div
        className={clsx(classes.searchWrapperStyle, classes.bottomWrapperStyle)}
      >
        <Select
          value={null}
          placeholder={tagsLabel}
          onChange={onKeywordChange}
          options={tagsList}
          autosize={true}
          styles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
            }),
            control: (provided, state) => ({
              ...provided,
              boxShadow: 'none',
              border: 'none',
              background: 'none',
            }),
            menu: (provided, state) => ({
              ...provided,
              // border: 'none',
              // boxShadow: 'none',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused && COLORS.backgroundGray85,
              color: state.isFocused && COLORS.main,
              fontWeight: state.isFocused && FONT_WEIGHT.bold,
            }),
          }}
        />
      </div>
    </>
  );
};

export default SearchInput;
