import { combineReducers } from 'redux';
import app from './app/reducer';
import api from './api/reducer';

const createRootReducer = () =>
  combineReducers({
    app,
    api,
  });

export default createRootReducer;
