import * as C from '../common/constants';
import createRootReducer from '../common/reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';

const middleware = [thunk];
const logger = createLogger({
  collapsed: true,
});

if (process.env.NODE_ENV !== C.PRODUCTION) middleware.push(logger);

const enhancer = composeWithDevTools(applyMiddleware(...middleware));
const store = () => createStore(createRootReducer(), {}, enhancer);

export default store;
