import * as C from '../common/constants';
import React, { useEffect } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RequestID } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconAbout } from '../assets/svg/icon_about.svg';
import { ReactComponent as IconNews } from '../assets/svg/icon_news.svg';
import { ReactComponent as IconVisit } from '../assets/svg/icon_visit.svg';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const icons = {
  icon_about: IconAbout,
  icon_news: IconNews,
  icon_visit: IconVisit,
};

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      pictoMainContainerStyle: {
        position: 'relative',
        width: '100%',
        minHeight: `${C.PICTO_HEIGHT}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      wrapperStyle: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        height: '100%',
      },
      pictoContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '40px',
      },
      pictoStyle: {
        position: 'relative',
        maxWidth: '370px',
        width: '100%',
        height: '126px',
        border: `2px solid ${COLORS.circleGray}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 10px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      titleStyle: {
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '20px',
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        marginTop: '24px',
        textAlign: 'center',
      },
      iconStyle: {
        position: 'absolute',
        top: '-40px',
        width: '80px',
        height: '80px',
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
      [`@media (max-width: 740px)`]: {
        pictoContainerStyle: {
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          '& > div': {
            margin: '30px 10px 20px 10px',
            '&:first-child': {
              marginLeft: '10px',
            },
            '&:last-child': {
              marginRight: '10px',
            },
          },
        },
      },
    })
);

const PictoBlocks = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);
  const { [RequestID.PICTO]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.PICTO));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return null;
  }

  const { pages } = currentData;

  const onBlockOpen = (
    event: React.SyntheticEvent,
    uri: string,
    link?: string
  ) => {
    event.stopPropagation();
    event.preventDefault();

    // open direct links in new tab
    if (link) {
      return window.open(link, '_blank');
    }

    return uri && history.push(uri);
  };

  const getPicto = ({ content, svg }, index: number) => {
    const title: string = getText(content, 'title', lng);
    const link: string = getText(content, 'link', lng);
    const safeName: string = svg[0].safeName;
    const Icon = icons[safeName];
    const uri: string = content.uri;

    return (
      <div
        key={index}
        className={classes.pictoStyle}
        onClick={(e) => onBlockOpen(e, uri, link)}
      >
        <Icon className={classes.iconStyle} />
        <div className={classes.titleStyle}>{title}</div>
      </div>
    );
  };

  return (
    <div className={classes.pictoMainContainerStyle}>
      <div className={classes.wrapperStyle}>
        <div className={classes.pictoContainerStyle}>{pages.map(getPicto)}</div>
      </div>
    </div>
  );
};

export default PictoBlocks;
