import * as C from '../common/constants';
import BlogBlock from './BlogBlock';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHeader from './PageHeader';
import React, { useEffect, useState, useContext } from 'react';
import SearchSelect from './SearchSelect';
import SearchTags from './SearchTags';
import LoadMore from './LoadMore';
import VideoDialog from './VideoDialog';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng, RequestID } from '../common/enums';
import { fetchSearchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ScrollContext } from './Layout';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const formatsData = [
  {
    value: 'interactive',
    text_lv: 'Interaktīvie resursi',
    text_en: 'Interactive resources',
  },
  {
    value: 'gallery',
    text_lv: 'Galerijas un virtuālās izstādes',
    text_en: 'Galleries and virtual exhibitions',
  },
  {
    value: 'videoaudio',
    text_lv: 'Video un audio',
    text_en: 'Video and audio',
  },
  {
    value: 'research',
    text_lv: 'Pētījumi un izdevumi',
    text_en: 'Studies and publications',
  },
  {
    value: 'learning',
    text_lv: 'Skolām un ģimenēm',
    text_en: 'For schools and families',
  },
];

const timelineData = [
  {
    value: 'ancienthistory',
    text_lv: 'Senvēsture',
    text_en: 'Ancient History',
  },
  { value: 'middleages', text_lv: 'Viduslaiki', text_en: 'Middle Ages' },
  {
    value: 'newtimes',
    text_lv: 'Jaunie laiki',
    text_en: 'Early Modern Period',
  },
  {
    value: 'recenttimes',
    text_lv: 'Jaunākie laiki',
    text_en: 'Late Modern Period',
  },
  {
    value: 'presentday',
    text_lv: 'Mūsdienas',
    text_en: 'Contemporary History',
  },
];

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      searchListContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '60px',
        flex: '1 1 auto',
      },
      wrapperStyle: {
        height: '100%',
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        padding: '30px 0',
        display: 'flex',
        flexDirection: 'column',
      },
      listWrapperStyle: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      leftSideStyle: {
        width: '100%',
        height: '100%',
        maxWidth: `${C.MAX_SEARCH_LIST_WIDTH}px`,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: '50px',
      },
      rightSideStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${C.MAX_SEARCH_RIGHT_WIDTH}px`,
      },
      tagsParentContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      noSearchContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      noSearchTitleStyle: {
        width: '100%',
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        fontSize: '32px',
        textAlign: 'center',
      },
      noSearchTextStyle: {
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.previewGray,
        fontSize: '18px',
        marginTop: '10px',
        textAlign: 'center',
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
        leftSideStyle: {
          maxWidth: '100%',
          marginRight: 0,
        },
        listWrapperStyle: {
          flexDirection: 'column-reverse',
        },
        rightSideStyle: {
          maxWidth: '100%',
          width: '100%',
          flexDirection: 'row',
        },
        tagsParentContainerStyle: {
          display: 'flex',
          flexDirection: 'row',
        },
      },
      [`@media (max-width: 700px)`]: {
        rightSideStyle: {
          flexDirection: 'column',
        },
      },
    })
);

const SearchList = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);
  const scrollNode = useContext(ScrollContext);

  const location = useLocation();
  const searchParams = queryString.parse(location?.search);
  const searchString = queryString.stringify(searchParams);

  // const id = window.location.pathname;
  const { [RequestID.SEARCH]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchSearchData(searchString));

    if (history.action === 'POP') {
      const scrollPosition = sessionStorage.getItem(RequestID.SEARCH);
      // sessionStorage.removeItem(id);
      scrollNode?.scrollTo(0, parseInt(scrollPosition));
    } else {
      scrollNode?.scrollTo(0, 0);
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const [dialogData, setDialog] = useState(null);

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  const onBlockOpen = (subData?: any, link?: string) => {
    // open youtube video and audio as popups
    if (link?.includes('//youtu') || subData?.audio?.length) {
      return setDialog(subData);
    }

    // open direct links in new tab
    if (link) {
      return window.open(link, '_blank');
    }

    const subUri = subData?.content?.uri;

    if (subUri) {
      const scrollPosition = scrollNode?.scrollTop;
      sessionStorage.setItem(RequestID.SEARCH, scrollPosition.toString());

      history.push(`/${subUri}`);
    }
  };

  const getEmptySearch = () => {
    const { noSearch } = currentData;
    const title: string = getText(noSearch?.content, 'title', lng);
    const text: string = getText(noSearch?.content, 'text', lng);

    return (
      <div className={classes.noSearchContainerStyle}>
        <div className={classes.noSearchTitleStyle}>{title}</div>
        <div className={classes.noSearchTextStyle}>{text}</div>
      </div>
    );
  };

  const { pages, pageNr, totalItems } = currentData;
  const isMore = pages?.length < totalItems;

  const searchResults = searchParams?.input
    ? searchParams?.input
    : searchParams?.tags;

  const onDialogClose = () => {
    setDialog(null);
  };

  const onLoadMore = () => {
    dispatch(fetchSearchData(searchString, pageNr + 1));
  };

  const timelineLabel: string = lng === Lng.LV ? 'Laikmets' : 'Timeline';
  const formatLabel: string = lng === Lng.LV ? 'Formāts' : 'Format';
  const TITLE: string = lng === Lng.LV ? 'Rezultāti:' : 'Results:';
  const title = `${TITLE} ${searchResults || ''}`;

  return (
    <div className={classes.searchListContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader title={title} />
        <div className={classes.listWrapperStyle}>
          <div className={classes.leftSideStyle}>
            {!pages?.length
              ? getEmptySearch()
              : pages?.map((data, index) => (
                  <BlogBlock
                    data={data}
                    key={index}
                    onBlockOpen={onBlockOpen}
                    type={RequestID.SEARCH}
                  />
                ))}
          </div>
          <div className={classes.rightSideStyle}>
            {currentData?.searchTags && (
              <SearchTags data={currentData?.searchTags} />
            )}
            <div className={classes.tagsParentContainerStyle}>
              <SearchSelect
                title={timelineLabel}
                id={'timeline'}
                data={timelineData}
              />
              <SearchSelect
                title={formatLabel}
                id={'formats'}
                data={formatsData}
              />
            </div>
          </div>
        </div>
        {isMore && <LoadMore data={currentData} onLoadMore={onLoadMore} />}
      </div>
      {Boolean(dialogData) && (
        <VideoDialog onClose={onDialogClose} data={dialogData} />
      )}
    </div>
  );
};

export default SearchList;
