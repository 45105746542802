import * as C from '../constants';

export function setLanguage(payload) {
  return {
    type: C.SET_LANGUAGE,
    payload,
  };
}

export function storeError(payload: string) {
  return {
    type: C.STORE_ERROR,
    payload,
  };
}

export function closeError() {
  return {
    type: C.CLOSE_ERROR,
  };
}
