import React, { useState, useRef, useEffect } from 'react';
import { getPath } from '../common/utils/helpers';
import { gsap } from 'gsap';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  images: any;
}

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      headerImageContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      headerImageWrapperStyle: {
        width: '100%',
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
);

const HeaderSlider = ({ images }: IProps): JSX.Element => {
  const classes = useStyles();
  const headerImage = useRef(null);
  const [currentImageNr, setImageNr] = useState(0);

  const currentImage = images?.[currentImageNr];
  const imgUrl: string = `${urlPrefix}${currentImage?.url}`;

  const onRepeat = () => {
    const newNr = currentImageNr >= images.length - 1 ? 0 : currentImageNr + 1;

    setImageNr(newNr);
  };

  useEffect(() => {
    gsap.fromTo(
      headerImage.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
        repeat: 1,
        yoyo: true,
        onComplete: onRepeat,
      }
    );
  }, [currentImageNr]);

  return (
    <div className={classes.headerImageContainerStyle}>
      <div className={classes.headerImageWrapperStyle} ref={headerImage}>
        <img src={imgUrl} alt="" className={classes.imgStyle} />
      </div>
    </div>
  );
};

export default HeaderSlider;
