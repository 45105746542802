import * as C from '../constants';

const INITIAL_STATE = {
  data: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  if (type === C.CLEAR_SEARCH_DATA) {
    const data = {
      ...state.data,
      search: null,
    };

    return {
      ...state,
      data,
    };
  }

  if (type) {
    const currentData = state.data?.[type];
    const newPayload = { ...payload };

    if (currentData && newPayload?.pageNr === currentData?.pageNr + 1) {
      newPayload.pages = [...currentData.pages, ...payload.pages];
    }

    const data = {
      ...state.data,
      [type]: newPayload,
    };

    return {
      ...state,
      data,
    };
  }

  return state;
};
