import * as C from '../common/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageHeader from './PageHeader';
import React, { useEffect } from 'react';
import Story from './Story';
import { fetchData } from '../common/api/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      aboutSubContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '1 1 auto',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
    })
);

const AboutSub = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const id = window.location.pathname;
  const { [id]: currentData } = useSelector((state) => state.api.data);

  useEffect(() => {
    dispatch(fetchData(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.aboutSubContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader />
        <Story data={currentData} />
      </div>
    </div>
  );
};

export default AboutSub;
