import * as C from '../common/constants';
import BlogBlock from './BlogBlock';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadMore from './LoadMore';
import PageHeader from './PageHeader';
import React, { useEffect, useState, useContext } from 'react';
import VideoDialog from './VideoDialog';
import { fetchData } from '../common/api/actions';
import { getText } from '../common/utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ScrollContext } from './Layout';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      blogListContainerStyle: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        flex: '1 1 auto',
      },
      wrapperStyle: {
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '30px 0',
        alignItems: 'center',
      },
      loaderStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      [`@media (max-width: 1230px)`]: {
        wrapperStyle: {
          boxSizing: 'border-box',
          padding: '30px',
        },
      },
    })
);

interface IProps {
  id?: any;
}

const BlogList = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const lng = useSelector((state) => state.app.lng);
  const scrollNode = useContext(ScrollContext);

  const id = location.pathname;
  const { [id]: currentData } = useSelector((state) => state.api.data);

  useEffect(() => {
    dispatch(fetchData(id));

    if (history.action === 'POP') {
      const scrollPosition = sessionStorage.getItem(id);
      // sessionStorage.removeItem(id);
      scrollNode?.scrollTo(0, parseInt(scrollPosition));
    } else {
      scrollNode?.scrollTo(0, 0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [dialogData, setDialog] = useState(null);

  if (!currentData) {
    return (
      <div className={classes.loaderStyle}>
        <CircularProgress />
      </div>
    );
  }

  const { content, totalItems, pageNr, pages } = currentData;
  const title: string = content && getText(content, 'title', lng);

  const onBlockOpen = (subData?: any, link?: string) => {
    // open youtube video and audio as popups
    if (link?.includes('//youtu') || subData?.audio?.length) {
      return setDialog(subData);
    }

    // open direct links in new tab
    if (link) {
      return window.open(link, '_blank');
    }

    const subUri = subData?.content?.uri;

    if (subUri) {
      const scrollPosition = scrollNode?.scrollTop;
      sessionStorage.setItem(id, scrollPosition.toString());

      history.push(`/${subUri}`);
    }
  };

  const onDialogClose = () => {
    setDialog(null);
  };

  const isMore = pages?.length < totalItems;

  const onLoadMore = () => {
    dispatch(fetchData(id, pageNr + 1));
  };

  return (
    <div className={classes.blogListContainerStyle}>
      <div className={classes.wrapperStyle}>
        <PageHeader title={title} />
        {pages?.map?.((data, index) => (
          <BlogBlock data={data} key={index} onBlockOpen={onBlockOpen} />
        ))}
        {isMore && <LoadMore data={currentData} onLoadMore={onLoadMore} />}
      </div>
      {Boolean(dialogData) && (
        <VideoDialog onClose={onDialogClose} data={dialogData} />
      )}
    </div>
  );
};

export default BlogList;
