import * as C from '../common/constants';
import * as yaml from 'js-yaml';
import React, { useEffect } from 'react';
import FooterSlider from './FooterSlider';
import { COLORS, FONTS } from '../common/theme';
import { ReactComponent as facebook } from '../assets/svg/facebook.svg';
import { ReactComponent as instagram } from '../assets/svg/instagram.svg';
import { ReactComponent as twitter } from '../assets/svg/twitter.svg';
import { RequestID } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { getPath, getText } from '../common/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const social = {
  Facebook: facebook,
  Twitter: twitter,
  Instagram: instagram,
};

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      footerContainerStyle: {
        position: 'relative',
        width: '100%',
        height: `${C.FOOTER_CONTAINER_HEIGHT}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '60px',
      },
      wrapperStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: `${C.MAX_WIDTH}px`,
        width: '100%',
        zIndex: 1,
      },
      infoStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: `${C.FOOTER_HEIGHT}px`,
        boxSizing: 'border-box',
        alignItems: 'center',
      },
      backgroundStyle: {
        position: 'absolute',
        width: '100%',
        height: `${C.FOOTER_HEIGHT}px`,
        background: COLORS.primary,
      },
      copyRightStyle: {
        margin: '4px 0',
        color: COLORS.secondary,
        fontSize: '14px',
        fontFamily: FONTS.alegreya,
      },
      textBlockStyle: {
        marginLeft: '50px',
        fontFamily: FONTS.alegreya,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      logoTextStyle: {
        display: 'flex',
        flexDirection: 'row',
      },
      connectStyle: {},
      titleStyle: {
        color: COLORS.textGray,
        fontSize: '18px',
        marginBottom: '8px',
        fontFamily: FONTS.alegreya,
      },
      emailStyle: {
        color: COLORS.textGray,
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        '& a': {
          color: COLORS.white,
          textDecoration: 'none',
          '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
              color: COLORS.textGray,
              transition: '0.5s ease',
            },
          },
        },
      },
      socialContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '14px',
      },
      socialStyle: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: `1px solid ${COLORS.socialCircleGray}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      imgWrapperStyle: {
        width: '20px',
        height: 'auto',
        maxHeight: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      imgStyle: {
        maxWidth: '20px',
        maxHeight: '20px',
      },
      logoStyle: {
        width: '120px',
        height: '120px',
      },
      [`@media (max-width: 1230px)`]: {
        infoStyle: {
          boxSizing: 'border-box',
          padding: '0 30px',
        },
      },
      [`@media (max-width: 900px)`]: {
        logoTextStyle: {
          margin: '20px 0',
        },
        titleStyle: {
          fontSize: '16px',
        },
        emailStyle: {
          fontSize: '14px',
        },
        infoStyle: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '330px',
        },
        textBlockStyle: {
          marginLeft: '20px',
        },
        backgroundStyle: {
          height: '330px',
        },
        copyRightStyle: {
          marginLeft: '30px',
        },
      },
      [`@media (max-width: 400px)`]: {
        infoStyle: {
          height: '450px',
        },
        backgroundStyle: {
          height: '450px',
        },
      },
    })
);

const Footer = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);
  const { [RequestID.FOOTER]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.FOOTER));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return null;
  }

  const { content } = currentData;
  const copyrightText: string = getText(content, 'copyright', lng);
  const titleText: string = getText(content, 'title', lng);

  const descriptionText: string = getText(content, 'text', lng);
  const [emailPrefix, emailAddress] = descriptionText.split(':');
  const emailString = `mailto:${emailAddress}?subject=`;

  const socialData = yaml.load(content.social);

  const urlPrefix = getPath();

  const logoImg = currentData?.images?.find((item) => item.name === 'logo');
  const logoImgUrl: string = Boolean(logoImg) && `${urlPrefix}${logoImg?.url}`;

  const footerImages = currentData?.images?.filter(
    (item) => item.name !== 'logo'
  );

  const getSocial = ({ platform, url }, index: number) => {
    const Social = social[platform];
    return (
      <div
        className={classes.socialStyle}
        key={index}
        onClick={() => window.open(url, '_blank')}
      >
        <div className={classes.imgWrapperStyle}>
          <Social className={classes.imgStyle} />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.footerContainerStyle}>
      <div className={classes.backgroundStyle} />
      <div className={classes.wrapperStyle}>
        <div className={classes.infoStyle}>
          <div className={classes.logoTextStyle}>
            {Boolean(logoImgUrl) && (
              <img src={logoImgUrl} alt="" className={classes.logoStyle} />
            )}
            <div className={classes.textBlockStyle}>
              <div className={classes.titleStyle}>{titleText}</div>
              <div className={classes.connectStyle}>
                <div className={classes.emailStyle}>
                  {emailPrefix}
                  <a href={emailString}>{emailAddress}</a>
                </div>
                <div className={classes.socialContainerStyle}>
                  {socialData.map(getSocial)}
                </div>
              </div>
            </div>
          </div>
          {footerImages && <FooterSlider images={footerImages} />}
        </div>
        <div className={classes.copyRightStyle}>{copyrightText}</div>
      </div>
    </div>
  );
};

export default Footer;
