import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { COLORS } from '../common/theme';
import { ReactComponent as Download } from '../assets/svg/direct-download.svg';
import { ReactComponent as Preview } from '../assets/svg/preview.svg';
import { getText, getPath } from '../common/utils/helpers';
import { useSelector } from 'react-redux';
import { Lng } from '../common/enums';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const urlPrefix = getPath();

interface IProps {
  file?: any;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      fileContainerStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
      },
      itemStyle: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: `2px solid ${COLORS.textGray}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      imgWrapperStyle: {
        width: '20px',
        height: 'auto',
        maxHeight: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      imgStyle: {
        maxWidth: '20px',
        maxHeight: '20px',
        fill: COLORS.textGray,
      },
    })
);

const BookDownload = ({ file }: IProps): JSX.Element => {
  const classes = useStyles();
  const lng = useSelector((state) => state.app.lng);
  const currentFileUrl: string = `${urlPrefix}${file?.url}`;
  const link: string = getText(file.meta, 'link', lng);

  const downloadTooltip: string = lng === Lng.LV ? 'Lejuplādēt' : 'Download';
  const previewTooltip: string = lng === Lng.LV ? 'Apskatīt' : 'Preview';

  const downloadFile = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    window.open(currentFileUrl, '_blank');
  };

  const previewFile = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    window.open(link, '_blank');
  };

  return (
    <div className={classes.fileContainerStyle}>
      <Tooltip title={downloadTooltip} placement="top" arrow>
        <div className={classes.itemStyle} onClick={downloadFile}>
          <div className={classes.imgWrapperStyle}>
            <Download className={classes.imgStyle} />
          </div>
        </div>
      </Tooltip>
      {link && (
        <Tooltip title={previewTooltip} placement="top" arrow>
          <div className={classes.itemStyle} onClick={previewFile}>
            <div className={classes.imgWrapperStyle}>
              <Preview className={classes.imgStyle} />
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default BookDownload;
