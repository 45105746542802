import * as C from '../constants';
import { Lng } from '../enums';

const INITIAL_STATE = Object.freeze({
  lng: Lng.LV,
  errorData: [],
});

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case C.SET_LANGUAGE: {
      return {
        ...state,
        lng: action.payload,
      };
    }
    case C.STORE_ERROR: {
      const existingErrors = state.errorData;
      const latestError = [action.payload];
      const errorData = [...existingErrors, ...latestError];

      return {
        ...state,
        errorData,
      };
    }
    case C.CLOSE_ERROR: {
      const existingErrors = state.errorData;
      // eslint-disable-next-line
      const removedError = existingErrors.shift();
      const errorData = [...existingErrors];

      return {
        ...state,
        errorData,
      };
    }
    default:
      return state;
  }
};
