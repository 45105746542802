import React, { useEffect } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS } from '../common/theme';
import { Lng } from '../common/enums';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../common/app/actions';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      containerStyle: {
        display: 'flex',
        flexDirection: 'row',
      },
      lngContainerStyle: {
        width: '34px',
        height: '34px',
        fontSize: '16px',
        lineHeight: '33px',
        borderRadius: '50%',
        border: `2px solid ${COLORS.textGray}`,
        fontFamily: FONTS.alegreya,
        color: COLORS.secondary,
        display: 'flex',
        justifyContent: 'center',
        marginRight: '4px',
        pointerEvents: 'auto',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      disabledStyle: {
        color: COLORS.textGray,
        border: `2px solid ${COLORS.circleGray}`,
        pointerEvents: 'none',
      },
    })
);

const Language = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const lng = useSelector((state) => state.app.lng);

  const searchParams = queryString.parse(search);

  useEffect(() => {
    if (searchParams?.lng === Lng.EN) {
      dispatch(setLanguage(searchParams?.lng));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onLanguageSwitch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const newLng = event.currentTarget.getAttribute('data-lng');
    dispatch(setLanguage(newLng));

    const newQuery = { ...searchParams };

    if (newLng === Lng.LV) {
      delete newQuery.lng;
    } else {
      newQuery.lng = Lng.EN;
    }

    const search = queryString.stringify(newQuery);

    history.push({
      pathname,
      search,
    });
  };

  const getLngItems = () => {
    return Object.keys(Lng).map((lngCode, index: number) => {
      const lngValue = Lng[lngCode];
      const isCurrentLng: boolean = lng === lngValue;

      return (
        <div
          key={index}
          className={clsx(
            classes.lngContainerStyle,
            isCurrentLng && classes.disabledStyle
          )}
          data-lng={lngValue}
          onClick={onLanguageSwitch}
        >
          {lngCode}
        </div>
      );
    });
  };

  return <div className={classes.containerStyle}>{getLngItems()}</div>;
};

export default Language;
