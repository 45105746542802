import * as ROUTES from '../common/routes';
import React, { useEffect } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RequestID } from '../common/enums';
import { fetchData } from '../common/api/actions';
import { getPath, getText } from '../common/utils/helpers';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      containerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      wrapperStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        width: '100%',
        height: '100%',
        marginTop: '50px',
      },
      headerStyle: {
        position: 'absolute',
        color: COLORS.black,
        fontWeight: FONT_WEIGHT.bold,
        fontSize: '80px',
        lineHeight: '90px',
        fontFamily: FONTS.alegreya,
      },
      titleContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      titleStyle: {
        color: COLORS.black,
        fontWeight: FONT_WEIGHT.bold,
        fontSize: '30px',
        fontFamily: FONTS.alegreya,
        marginTop: '30px',
      },
      textStyle: {
        color: COLORS.textDescription,
        fontWeight: FONT_WEIGHT.regular,
        fontSize: '20px',
        fontFamily: FONTS.alegreya,
        marginTop: '40px',
      },
      imgStyle: {
        marginTop: '50px',
      },
      buttonStyle: {
        color: COLORS.secondary,
        fontWeight: FONT_WEIGHT.bold,
        fontSize: '12px',
        fontFamily: FONTS.garamond,
        display: 'flex',
        alignItems: 'center',
        padding: '13px 40px',
        border: `1px solid ${COLORS.primary}`,
        textTransform: 'uppercase',
        marginTop: '30px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
    })
);

const NotFound = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.app.lng);
  const { [RequestID.NOT_FOUND]: currentData } = useSelector(
    (state) => state.api.data
  );

  useEffect(() => {
    dispatch(fetchData(RequestID.NOT_FOUND));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentData) {
    return null;
  }

  const { content, images } = currentData;
  const title: string = getText(content, 'title', lng);
  const text: string = getText(content, 'text', lng);
  const buttonText: string = getText(content, 'button', lng);
  const urlPrefix = getPath();
  const currentImg: string = `${urlPrefix}${images[0].url}`;

  const goHome = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    history.push(ROUTES.ROOT);
  };

  return (
    <div className={classes.containerStyle}>
      <div className={classes.wrapperStyle}>
        <div className={classes.headerStyle}>{'ERROR'}</div>
        <img src={currentImg} alt="" className={classes.imgStyle} />
        <div className={classes.titleContainerStyle}>
          <div className={classes.titleStyle}>{title}</div>
          <div className={classes.textStyle}>{text}</div>
          <div className={classes.buttonStyle} onClick={goHome}>
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
