export const EMUZEJS_URL: string = 'https://emuzejs.lnvm.lv';
export const PRODUCTION: string = 'production';

export const SET_LANGUAGE: string = 'SET_LANGUAGE';
export const STORE_ERROR: string = 'STORE_ERROR';
export const CLOSE_ERROR: string = 'CLOSE_ERROR';

export const MAX_WIDTH: number = 1170;
export const MAX_SEARCH_LIST_WIDTH: number = 780;
export const MAX_SEARCH_RIGHT_WIDTH: number = 340;
export const HEADER_HEIGHT: number = 120;
export const FOOTER_CONTAINER_HEIGHT: number = 188;
export const FOOTER_HEIGHT: number = 160;
export const MAIN_BLOCKS_MAX_HEIGHT: number = 640;
export const PICTO_HEIGHT: number = 280;

export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
