import Back from './Back';
import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import {
  StyleRules,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

interface IProps {
  title?: string;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      pageHeaderStyle: {
        position: 'relative',
        width: 'calc(100% - 90px)',
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        minHeight: '40px',
      },
      titleStyle: {
        fontFamily: FONTS.garamond,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '24px',
        lineHeight: '30px',
        color: COLORS.textBlack,
        textTransform: 'uppercase',
        marginTop: '6px',
      },
      backStyle: {
        position: 'relative',
        zIndex: 10,
      },
    })
);

const PageHeader = ({ title }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.pageHeaderStyle}>
      <h3 className={classes.titleStyle}>{title}</h3>
      <div className={classes.backStyle}>
        <Back />
      </div>
    </div>
  );
};

export default PageHeader;
